import { createSlice } from '@reduxjs/toolkit';


interface PlayerStateInterface {
  queue: [],
  track: {}
}

const initialState: PlayerStateInterface = {
  queue: [],
  track: {}
}


export const playerSlice = createSlice({
    name: 'player',
    initialState,
    reducers: {
      
    },
    extraReducers: builder => {
        
    }
});

// export const { } = playerSlice.actions


export default playerSlice.reducer
