import Chart from 'react-apexcharts';
import { Skeleton } from '@mui/material'
import React, { useMemo } from 'react'
import placeholder from '../../../assets/placeholder_artist.jpg';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useGetUserQuery } from '../../../api/userApiSlice';
import { useGetTrackQuery } from '../../../api/trackApiSlice';
import { useParams } from 'react-router';


const TrackArtists = () => {

    let { id } = useParams()
    const {
        data: user
    } = useGetUserQuery()

    const {
        data: track,
    } = useGetTrackQuery({id, user_id: user ? user._id : null})

    const allSingleArtists = useMemo(() => {
        var mainArtists = []
        if (track && track.track.main_artists) {
            track.track.main_artists.forEach( artist => { if (!mainArtists.some(art => art._id === artist._id )) {mainArtists.push(artist)} }) 
            track.track.featured_artists.forEach( artist => { if (!mainArtists.some(art => art._id === artist._id )) {mainArtists.push(artist)} }) 
            track.track.producers.forEach( artist => { if (!mainArtists.some(art => art._id === artist._id )) {mainArtists.push(artist)} }) 
        }
        return mainArtists
    }, [track])

    const allArtists = useMemo(() => {
        var mainArtists = []
        if (track && track.track.main_artists) {
            track.track.main_artists.forEach( artist => { mainArtists.push(artist)} ) 
            track.track.featured_artists.forEach( artist => { mainArtists.push(artist) }) 
            track.track.producers.forEach( artist => { mainArtists.push(artist) }) 
        }
        return mainArtists
    }, [track])


    return track ?
    <Grid2 xs={12} sx={{overflow: 'hidden', height: '72vh', width: '100%'}}>
        <Chart 
            options={{
                plotOptions: {
                    treemap: {
                        useFillColorAsStroke: true,
                    }
                },
                grid: {
                    show: false,
                },
                theme: {
                    mode: 'dark', 
                },
                chart: {
                    type: "treemap",
                    foreColor: '#FFFFFF',
                    background: '#ffffff00',
                    toolbar: {
                        show: false,
                    },
                    animations: {
                        enabled: false,
                    },
                },
                fill: {
                    type: 'image',
                    image: {
                        src: allSingleArtists.map( artist => artist.avatar_url ? artist.avatar_url.replace("{w}","1200").replace("{h}","800").replace("cw.png","cc.png") : (artist.apple_avatar ? artist.apple_avatar.replace("cw.png","cc.png").replace("{w}","1200").replace("{h}","800") : placeholder))
                    }
                }
                }}
                series={
                    allSingleArtists.map( artist =>  [{
                        name: artist.artist_name,
                        data: [{
                            x: artist.artist_name,
                            y: allArtists.filter( gArtist => gArtist._id === artist._id).length
                        }]
                    }].reduce((acc, val) => [...acc, ...val]))
                }                    
                type="treemap" 
                width={'103%'} 
                height={'100%'}
            />                                  
        </Grid2>
    : <Skeleton/>
}

export default TrackArtists