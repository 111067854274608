import React from 'react';
import withMK from '../../../hoc/withMK';
import QueueItem, { QueueItemState } from './QueueItem';
import MusicOffIcon from '@mui/icons-material/MusicOff';

interface QueueListProps extends MKProps {
  removeItemFunc: (queuePosition: number) => void;
}

interface QueueListState {
  filteredItems: MusicKit.QueueItem[];
}

class QueueList extends React.Component<QueueListProps, QueueListState> {
  public static queueState(index: number, position: number) {
    if (index < position) {
      return QueueItemState.Played;
    }

    if (index === position) {
      return QueueItemState.Playing;
    }

    return QueueItemState.Queued;
  }

  public static getDerivedStateFromProps(props: QueueListProps) {
    const { queue } = props.mk.instance;
    const filteredItems = (queue.items as MusicKit.QueueItem[])
      .map((item, index) => {
        // eslint-disable-next-line no-param-reassign
        item.queueState = QueueList.queueState(index, queue.position);
        // eslint-disable-next-line no-param-reassign
        item.queuePosition = index;

        return item;
      })
      .filter(item => item.queueState !== QueueItemState.Played);

    return {
      filteredItems,
    };
  }
  constructor(props: QueueListProps) {
    super(props);

    this.state = QueueList.getDerivedStateFromProps(this.props);
  }

  public render() {
    const { filteredItems: items } = this.state;
    const { removeItemFunc } = this.props;

    return items.length > 0 
    ? items.map( item =>  <QueueItem item={item} removeItemFunc={removeItemFunc} />) 
    : <MusicOffIcon htmlColor='white' sx={{pt: 4, width: '100%', fontSize: '42pt'}} />
  }
}

const bindings = {
  [MusicKit.Events.queueItemForStartPosition]: 'queueItemForStartPosition',
  [MusicKit.Events.queueItemsDidChange]: 'queueItems',
  [MusicKit.Events.queuePositionDidChange]: 'queuePosition',
};

export default withMK(QueueList, bindings);
