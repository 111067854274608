import React from 'react'
import { Skeleton, Stack, Chip, Grid } from '@mui/material'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router'
import { useGetTrackQuery } from '../../api/trackApiSlice';
import AddPlaylistDialog from './dialog/AddPlaylistDialog';
import { useGetUserQuery } from '../../api/userApiSlice';
import TrackSideMenu from './components/board/TrackSideMenu';
import SubscribePopup from '../artist/dialog/SubscribePopup';


const chipStyle = { 
    color:'#FFF',
    height: '2.6vh',
    borderRadius: '1.3vh',
    fontFamily: 'Montserrat',
    fontSize: '1.2vmin',
    marginRight: '18px',
    display: 'flex',
    width: '15vw',
    background: '#00000080',
    marginBottom: '18px',
    '& span': {padding: '0 18px'}
}

const TrackBoard = () => {
    let { id } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const [open, setOpen] = React.useState(false)

    const {
        data: user
    } = useGetUserQuery({})

    const {
        data: track,
    } = useGetTrackQuery({id, user_id: user ? user._id : null})

    const showTeam = () => {
        navigate('team')
    }

    const showStats = () => {
        navigate('stats')
    }

    const handleClickOpen = () => {
        setOpen(true)
    }
    
    const handleClose = () => {
        setOpen(false)
    }


    return track && track !== null
    ? 
        <Grid container spacing={2} sx={{height: '100%'}}>
            <AddPlaylistDialog />
            <SubscribePopup handleClose={handleClose} open={open} />
            <TrackSideMenu handleClickOpen={handleClickOpen}/>
            <Grid item lg={8} xs={12}>
                <Grid container spacing={0} sx={{height: '100%', borderRadius: '16px', position: 'relative',  backdropFilter: 'blur(20px)  brightness(70%)', margin: '0!important', padding: '0!important'}}>
                    <Stack direction={'column'} sx={{width: '100%' }} alignItems={'center'} >
                        <Stack direction={'row'} sx={{height: '3vh', overflowY: 'hidden', overflowX: 'hidden', p: 2}} >
                            <Chip key={'Équipe'} label={'Équipe'} variant={!location.pathname.includes('stats') ? 'outlined' : 'filled' } sx={chipStyle} onClick={showTeam}/>
                            <Chip key={'Stats'} label={'Stats'} variant={location.pathname.includes('stats') ? 'outlined' : 'filled' } sx={chipStyle} onClick={showStats}/>
                        </Stack>
                        <Outlet />
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    : 
        <Skeleton/>
}


export default TrackBoard