import { Button, Typography, Box, Avatar, Badge, Menu, Divider } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ShareIcon from '@mui/icons-material/Share'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import PauseIcon from '@mui/icons-material/Pause';
import { useLikeByTypeMutation } from '../../api/userApiSlice'
import SubscribePopup from '../artist/dialog/SubscribePopup'
import SubscribeAMPopup from '../artist/dialog/SubscribeAMPopup'
import { pause, playTrack } from '../../services/MusicPlayerApi'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PianoIcon from '@mui/icons-material/Piano';
import withMK from '../../hoc/withMK'
import { useDispatch } from 'react-redux'
import { setAddPlaylistTrack } from '../../actions/artistSlice'

interface TrackRowProps extends MKProps {
    track: Track,
    index: number,
    maxProducers: number,
    fromAlbum: Boolean
}

const TrackRow = ({track, index, maxProducers = 2, fromAlbum, mk}: TrackRowProps) => {
    let { id } = useParams()
    const [likeTrack] = useLikeByTypeMutation()
    const [open, setOpen] = React.useState(false)
    const [openAM, setOpenAM] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const [anchorElProd, setAnchorElProd] = React.useState<null | HTMLElement>(null)
    const openMenu = Boolean(anchorEl)
    const openProducersMenu = Boolean(anchorElProd)
    const currentTrackId = mk && mk.instance.queue.currentItem ? mk.instance.queue.currentItem.id : null
    const playing = mk?.instance?.isPlaying
    let dispatch = useDispatch()


    const openSubscribePopup = () => {
        setOpen(true)
    }
    
    const handleClose = () => {
        setOpen(false)
        setOpenAM(false)
    }

    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    const handleCloseProdMenu = () => {
        setAnchorElProd(null)
    }
    
    const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClickProducersMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElProd(event.currentTarget)
    }
    // const openSubscribeAMPopup = () => {
    //     setOpenAM(true)
    // }


    const likeTrackById = async (ID: String) => {
        try {
            var likedTrack: any = await likeTrack({ target_id: ID, type: 'track' })
            if (likedTrack.error) {
                openSubscribePopup()
            } else {
                
            }
        } catch (err) {
            console.log(err)
        }
    }

    const addTrackToPlaylistById = async (track: Track) => {
        dispatch(setAddPlaylistTrack({track: track}))
        // try {
        //     var getPlaylist: any = await useGetUserPlaylistsQuery
        //     if (getPlaylist.error) {
        //         openSubscribePopup()
        //     } else {
        //         console.log(getPlaylist)
        //     }
        // } catch (err) {
        //     console.log(err)
        // }
    }

    return <Box sx={{width: '100%', position: 'relative', '&:hover': { backgroundColor: '#00000060' } }} >
        <SubscribePopup handleClose={handleClose} open={open} />
        <SubscribeAMPopup handleClose={handleClose} open={openAM} />
        <Stack direction="row" sx={{pb: 0}} alignItems={'center'}>
            { 
                fromAlbum

                ?   playing && track.apple_id+'' === currentTrackId+''
                    ? <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                            <PauseIcon onClick={() => pause()} sx={{background: '#17043d',border: '4px solid #17043d', borderRadius: '50%', fontSize: '16pt'}}  />
                        }
                    >
                        <Typography variant='h4' sx={{width: '4vh', textAlign: 'center'}}>{index+1}</Typography>
                    </Badge>
                    : <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                            <PlayArrowIcon 
                                onClick={() => playTrack([new MusicKit.MediaItem({type: 'song', id: track.apple_id+''})], 0)} 
                                htmlColor='#d2d2d2' 
                                sx={{background: '#17043d',border: '4px solid #17043d', borderRadius: '50%', fontSize: '16pt'}} />
                        }
                    >
                        <Typography variant='h4' sx={{width: '4vh', textAlign: 'center'}}>{index+1}</Typography>
                    </Badge>
                : playing && track.apple_id+'' === currentTrackId+''
                    ? <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                            <PauseIcon onClick={() => pause()} sx={{background: '#17043d',border: '4px solid #17043d', borderRadius: '50%', fontSize: '16pt'}}  />
                        }
                    >
                        <Avatar 
                            onClick={() => pause()}
                            variant="square" 
                            alt={track.album.apple_name} 
                            src={track.album.apple_artwork_url.replace("{w}","1200").replace("{h}","800")} 
                            sx={{width: '6vh', height: '6vh' }} />
                    </Badge>
                    : <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                            <PlayArrowIcon 
                                onClick={() => playTrack([new MusicKit.MediaItem({type: 'song', id: track.apple_id+''})], 0)} 
                                htmlColor='#d2d2d2' 
                                sx={{background: '#17043d',border: '4px solid #17043d', borderRadius: '50%', fontSize: '16pt'}} />
                        }
                    >
                        <Avatar 
                            onClick={() => playTrack([new MusicKit.MediaItem({type: 'song', id: track.apple_id+''})], 0)}
                            variant="square" 
                            alt={track.album.apple_name} 
                            src={track.album.apple_artwork_url.replace("{w}","1200").replace("{h}","800")} 
                            sx={{ width: '6vh', height: '6vh' }} />
                    </Badge>

            }
            <Stack justifyContent="center" sx={{ marginLeft: '16px', width: '55vw', color: 'white', '&a' : { display: 'inline', color: 'red!important'} }}>
                <Typography sx={{fontWeight: 500, fontSize: '1.2vmin', pt: 1}}><Link to={`/track/${track._id}`} >{track.apple_title}</Link></Typography>
                <Stack direction="row">
                    <Typography sx={{fontStyle: 'italic', fontWeight: 200, fontSize: '1vmin'}}>
                        {
                            track && track.main_artists.map((artist, i) => {
                                return i === track.main_artists.length - 1
                                    ? artist._id === id ? artist.artist_name : <Link key={Date.now() + i + artist._id} to={`/artist/${artist._id}`} > {artist.artist_name} </Link>
                                    : artist._id === id ? artist.artist_name + ', ' : <span key={Date.now() + i + artist._id}><Link key={Date.now() + i + artist._id} to={`/artist/${artist._id}`} > {artist.artist_name} </Link>, </span>
                            })
                        }
                    </Typography>
                    {
                        track?.featured_artists && track?.featured_artists.length > 0
                            ?
                            <Typography color={'white'} sx={{ marginLeft: '4px', color: 'white', fontStyle: 'italic', fontWeight: 200, fontSize: '1vmin' }}>ft. {track.featured_artists.map((artist, i) => {
                                return track?.featured_artists && i === track.featured_artists.length - 1
                                    ? artist._id === id ? artist.artist_name : <Link key={Date.now() + i + artist._id} to={`/artist/${artist._id}`} > {artist.artist_name} </Link>
                                    : artist._id === id ? artist.artist_name + ', ' : <span key={Date.now() + i + artist._id}><Link to={`/artist/${artist._id}`} > {artist.artist_name} </Link>, </span>
                            }
                            )}
                            </Typography>
                            : ''
                    }
                </Stack>
                <Box sx={{minHeight: '2vh'}}>
                    {
                        track.producers !== null && track.producers.length > 0
                            ?
                            <Box sx={{color: 'white', display: 'block', textAlign: 'right', whiteSpace: 'nowrap', fontWeight: 400, fontSize: '1vmin'}}>{track.producers.slice(0,maxProducers).map((artist, i) => {
                                return track.producers && i === Math.min(maxProducers - 1, track.producers.length - 1)
                                    ? artist._id === id 
                                        ? artist.artist_name + (track.producers!.length >= maxProducers ? ',...' : '') 
                                        : <Stack key={i} direction={'row'} sx={{display: 'inline'}}>
                                            <Link key={Date.now() + i + artist._id} to={`/artist/${artist._id}`} > {artist.artist_name} </Link>
                                            <Typography sx={{display: 'inline'}}>{ (track.producers!.length > maxProducers ? ',...' : '') }</Typography>
                                        </Stack>
                                    : artist._id === id 
                                        ? (artist.artist_name + ', ' ) 
                                        : <span key={Date.now() + i + artist._id}><Link to={`/artist/${artist._id}`} > {artist.artist_name} </Link>, </span>
                            }
                            )}
                            </Box>
                            : ''
                    }
                    </Box>
            </Stack>
            <Menu
                id="actions-menu"
                MenuListProps={{
                'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
            >
                {
                    track.producers && track.producers.length >= 3 
                    ?
                    <Button onClick={handleClickProducersMenu} key={5} color="secondary"><PianoIcon htmlColor='#17043d' /></Button>
                    : ''
                }
                {
                    playing && track.apple_id+'' === currentTrackId+''
                        ? <Button key={8} color="secondary" onClick={() => pause()} ><PauseIcon htmlColor='#17043d' /></Button>
                        : <Button key={8} color="secondary" onClick={() => playTrack([new MusicKit.MediaItem({type: 'song', id: track.apple_id+''})], 0)} ><PlayArrowIcon htmlColor='#17043d' /></Button>

                }
                <Button key={7} onClick={() => addTrackToPlaylistById(track)} color="secondary"><PlaylistAddIcon htmlColor='#17043d' /></Button>
                <Button key={6} color="secondary" onClick={() => likeTrackById(track._id)} ><FavoriteIcon htmlColor='#17043d' /></Button>
                <Button key={11} color="secondary"><ShareIcon htmlColor='#17043d' /></Button>
            </Menu>
            <Menu
                id="producers-menu"
                MenuListProps={{
                'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorElProd}
                open={openProducersMenu}
                onClose={handleCloseProdMenu}
            >
                {
                        track.producers !== null && track.producers.length > 0
                            ?
                            <Box sx={{color: '#17043d', display: 'block', textAlign: 'right', whiteSpace: 'nowrap', pl: 2, pr: 2}}>{track.producers.map((artist, i) => {
                                return track.producers && i === track.producers.length - 1
                                    ? artist._id === id 
                                        ? artist.artist_name
                                        : <Link key={Date.now() + i + artist._id} to={`/artist/${artist._id}`} > <Typography sx={{color: '#17043d', display: 'inline'}}>{artist.artist_name}</Typography> </Link>
                                    : artist._id === id 
                                        ? (artist.artist_name + ', ' ) 
                                        : <span key={Date.now() + i + artist._id}><Link to={`/artist/${artist._id}`} > <Typography sx={{color: '#17043d', display: 'inline'}}>{artist.artist_name} </Typography></Link>, </span>
                            }
                            )}
                            </Box>
                            : ''
                    }
            </Menu>
            <Stack direction="row" sx={{ marginLeft: '16px', right: '5%' }}>
                <Button key={10} color="secondary" onClick={handleClickMenu}><MoreHorizIcon htmlColor='white' /></Button>
            </Stack>
        </Stack>
        <Divider sx={{ background: '#D2D2D2'}}/>
    </Box>
}


const bindings = {
    [MusicKit.Events.mediaItemDidChange]: 'mediaItem',
    [MusicKit.Events.queueItemsDidChange]: 'queueItems',
    [MusicKit.Events.queuePositionDidChange]: 'queuePosition',
    [MusicKit.Events.playbackStateDidChange]: 'playbackState',
}
  
export default withMK(TrackRow, bindings)