import { apiSlice } from "./apiSlice";

export const playlistApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPlaylist: builder.query({
            query: ({id}) => ({
                url: `/v1/playlists/${id}`,
                method: 'GET'
            })  
        })
    })
})

export const {
    useGetPlaylistQuery
} = playlistApiSlice