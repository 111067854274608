import { apiSlice } from "./apiSlice";

export const trackApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAlbum: builder.query({
            query: ({id}) => ({
                url: `/albums/${id}`,
                method: 'GET'
            })  
        }),
        getAlbumTracks: builder.query({
            query: ({id}) => ({
                url: `/v1/albums/${id}/tracks`,
                method: 'GET'
            })  
        }),
        getAlbumProducers: builder.query({
            query: ({id}) => ({
                url: `/v1/albums/${id}/producers`,
                method: 'GET'
            })  
        })
    })
})

export const {
    useGetAlbumQuery,
    useGetAlbumTracksQuery,
    useGetAlbumProducersQuery
} = trackApiSlice