import React from 'react'
import { Avatar, Button, Stack, Grid } from '@mui/material'
import logomeka from '../../assets/logo.png'
import iconeevent from '../../assets/event.png'
import iconediscover from '../../assets/discover.png'
import iconemusic from '../../assets/music.png'
import iconestats from '../../assets/stats.png'
import { Link, useNavigate } from 'react-router-dom'
import { useGetUserQuery } from '../../api/userApiSlice'

export function SideMenu() {

  const {
    data: user
  } = useGetUserQuery()

  const navigate = useNavigate()

  const goToHome = async (e) => {
      navigate(`/`)
  }

  return (
    <Grid item lg={2} md={2} xs={0} sx={{ height: '90%', display: { xs: 'none', md: 'flex', lg: 'flex'}}}>
        <Stack spacing={0} sx={{height: '100%', width: '100%'}}>
            <Button sx={{height: '16%',}} ><img alt="logo meka" height={'100%'} src={logomeka} onClick={goToHome} /></Button>
            <Button sx={{marginTop: '4vh', height: '14%', padding: '16px'}}><img alt="logo event" height={'100%'} src={iconeevent} /></Button>
            <Button sx={{marginTop: '4vh', height: '14%', padding: '16px'}}><img alt="logo music" height={'100%'} src={iconemusic} /></Button>
            <Button sx={{marginTop: '4vh', height: '14%', padding: '16px'}}><img alt="logo discover" height={'100%'} src={iconediscover} onClick={goToHome} /></Button>
            <Button sx={{marginTop: '4vh', height: '14%', padding: '16px'}}><img alt="logo stats" height={'100%'} src={iconestats} /></Button>
            { 
              user 
              ? <Button sx={{marginTop: '4vh', height: '16%', }} ><Link to={'/profile/' + user._id}><Avatar alt="User avatar" src={user.avatar_url} sx={{margin: '0 auto', height: '8vh', width: '8vh'}}/></Link></Button>
              : <Button sx={{marginTop: '4vh', height: '16%', }} ><Link to='/login'><Avatar alt="User avatar" sx={{margin: '0 auto', height: '8vh', width: '8vh'}}/></Link></Button>
            }
        </Stack>
    </Grid>
  );
}
