import { Avatar, Button, Container, Grid, Skeleton, Typography } from '@mui/material'
import React from 'react'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import { selectArtistResults, selectTrackResults } from '../../../actions/discoverSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@mui/system'
import { Link } from 'react-router-dom'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ShareIcon from '@mui/icons-material/Share'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import PauseIcon from '@mui/icons-material/Pause';
import placeholder from '../../../assets/placeholder_artist.jpg';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { useLikeByTypeMutation } from '../../../api/userApiSlice'

const LeftItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fefefe',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: '0',
    borderTop: '0px solid #9F9F9F',
}))

const SearchCardResults = () => {
  const dispatch = useDispatch()
  const [likeTrack] = useLikeByTypeMutation()

  const findedArtists = useSelector(selectArtistResults)
  const findedTracks = useSelector(selectTrackResults)
  const playingState = null
  const currentTrackId = null

  const likeTrackById = async (ID) => {
    dispatch(likeTrack({target_id: ID, type: 'track'}))
}

const playTrack = async (track) => {
    // await dispatch(authAppleMusicAsync())
    // await dispatch(addTrackToQueueAsync({track}))
    // dispatch(playTrackAsync())
}
const pause = async () => {
    // dispatch(pauseTrackAsync())
}
    return (
      <>
        <LeftItem sx={{height: '45%'}} >
        <Container sx={{ overflowX: 'hidden', overflowY: 'scroll', height: '100%', width: '96%'}}>
          {
            findedArtists && findedArtists.length > 0 
            ?
              findedArtists.map( artist => {
                return (
                <Link key={artist._id} to={`/artist/${artist._id}`} > 
                  <Grid container spacing={0} sx={{p: 1, mt: 1, mb: 1}}>
                    <Grid item xs={3}>
                      <Avatar src={artist.avatar_url ? artist.avatar_url.replace("cw.png","cc.png") : placeholder} sx={{width: '6vh', height: '6vh'}}/>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                          <Typography color={'primary.main'} textAlign={'left'}>{artist.artist_name}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography color={'primary.main'} textAlign={'left'}>{artist.genre_names.length > 0 ? artist.genre_names.join(', ') : '--'}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid2 item xs={3} >
                      <Stack direction={'row'} sx={{marginLeft: '8px', right: '5%'}}>
                        <Button color="primary" ><FavoriteIcon color='secondary.main'/></Button>
                        <Button color="primary" sx={{display: 'block'}}><ShareIcon color='primary.main'/></Button>
                      </Stack>
                    </Grid2>
                  </Grid>
                </Link>
              )})
            :
                <Skeleton />
          }
          </Container>
        </LeftItem>
        <LeftItem sx={{height: '45%', overflowX: 'scroll'}} >
        <Container sx={{ overflowX: 'hidden', overflowY: 'scroll', height: '100%', width: '96%'}}>

          {
            findedTracks && findedTracks.length > 0
            ?
              findedTracks.map( track => {
                return (
                  <Grid2 key={track._id} container spacing={0} sx={{position: 'relative'}} >
                      <Grid2 item xs={2}>
                        <Avatar variant="square" alt={track.album.apple_name} src={track.album.apple_artwork_url} sx={{width: '6vh', height: '6vh'}} />
                      </Grid2>
                      <Grid2 item xs={10}>
                        <Stack sx={{display: 'inline-block', marginLeft: '16px', width: '55vw'}}>
                          <Typography textAlign={'left'} color={'primary.main'}>{track.apple_title}</Typography>
                          <Stack direction="row" sx={{overflow: 'scroll'}}>
                            {
                              track.main_artists.map( (artist, i) =>  {
                                return i === track.main_artists.length - 1
                                    ? 
                                      <Link key={i + artist._id} to={`/artist/${artist._id}`} ><Typography color={'primary.main'}> { artist.artist_name } </Typography></Link>
                                    : 
                                      <Link key={i + artist._id} to={`/artist/${artist._id}`} ><Typography color={'primary.main'}> { artist.artist_name + ',' } </Typography></Link>
                                })
                            }
                            {
                                track.featured_artists.length > 0
                                ?
                                    track.featured_artists.map( (artist, i) =>  {
                                      return i === track.featured_artists.length - 1
                                          ? 
                                            <Link key={i + artist._id} to={`/artist/${artist._id}`} ><Typography color={'primary.main'} sx={{marginLeft: '4px'}}> { artist.artist_name } </Typography></Link>
                                          : 
                                            <Link key={i + artist._id} to={`/artist/${artist._id}`} ><Typography color={'primary.main'} sx={{marginLeft: '4px'}}> { artist.artist_name + ',' } </Typography></Link>
                                      })
                                : 
                                  ''
                            }
                          </Stack>
                          <Typography textAlign={'left'} color={'primary.main'}>{track.apple_id}</Typography>
                        </Stack>
                      </Grid2>
                      <Grid2 item xs={6} xsOffset={6}>
                        <Stack direction="row" sx={{marginLeft: '8px'}}>
                          {
                              playingState && track.apple_id === currentTrackId
                              ? 
                                <Button color="primary" onClick={() => pause()} ><PauseIcon color='primary.main'/></Button>
                              : 
                                <Button color="primary" onClick={() => playTrack(track)} ><PlayArrowIcon color='primary.main'/></Button>
                          }
                          <Button color="primary"  sx={{display: 'block'}}><PlaylistAddIcon color='primary.main'/></Button>
                          <Button color="primary" onClick={() => likeTrackById(track._id)} ><FavoriteIcon color='primary.main'/></Button>
                          <Button color="primary" sx={{display: 'block'}}><ShareIcon color='primary.main'/></Button>
                        </Stack>
                      </Grid2>
                  </Grid2>
                )
              })
            :
              <Skeleton />
          }
          </Container>
        </LeftItem>
      </>

    )
}

export default SearchCardResults