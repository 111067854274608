import { useSelector } from "react-redux"
import { selectCurrentToken } from "../actions/authSlice"
import { jwtDecode } from "jwt-decode";
import { ROLES } from "../core/config/roles"


const useAuth = () => {
    const token = useSelector(selectCurrentToken)
    let isAdmin = false
    if (token) {
        const decoded = jwtDecode(token)
        const { id, role } = decoded.UserInfo 
        isAdmin = role === ROLES.Admin
        // console.log( {
        //     name, role, isAdmin
        // })

        return {
            id, role, isAdmin
        }
    }

    return {
        id: '', role : '', isAdmin
    }
}

export default useAuth