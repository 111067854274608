import { Skeleton, Typography } from '@mui/material'
import { useGetBestsTracksQuery } from '../../../../api/discoverApiSlice'
import { selectCurrentStyle } from '../../../../actions/discoverSlice';
import { useSelector } from 'react-redux';
import BestTrackFocusHover from './BestTrackFocusHover';

const BestTrackFocus = () => {
    const selectedStyle = useSelector(selectCurrentStyle)

    const {
        data: newTracks,
        isFetching
    } = useGetBestsTracksQuery(selectedStyle)


    return newTracks && !isFetching 
    ? 
        newTracks.length > 0
        ? 
            <BestTrackFocusHover tracks={newTracks} />
        : 
            <Typography alignSelf={'center'}>Pas de mouvement</Typography>
    :
        <Skeleton variant="rectangular" width={'100%'} height={'55%'} sx={{bgcolor: '#00000068'}}/>

}

export default BestTrackFocus