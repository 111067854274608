import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDiscoverData, getSearchByTerm } from '../api/discoverApiSlice';

const initialState = {
    currentStyle: localStorage.getItem('style') ? localStorage.getItem('style') : 34,
    ranks: null,
    chart_styles: null,
    best_tracks: null,
    new_tracks: null,
    rankings: null,
    topArtists: null,
    topProducers: null,
    topLabels: null,
    topDates: null,
    topAlbums: null,
    searchTerm: null,
    topPlaylists: null,
    artistResults: null,
    trackResults: null,
    status: 'idle',
};

export const fetchDiscoverData = createAsyncThunk(
    'discover/getDiscoverData',
    async (style = 34) => {
        localStorage.setItem('style', style)
        var response = await getDiscoverData(style)
        return response
    }
);

export const sendSearchAsync = createAsyncThunk(
  'discover/sendSearchAsync',
  async (search) => {
      var response = await getSearchByTerm(search)
      return {res: response, searchTerm: search}
  }
);

export const discoverSlice = createSlice({
    name: 'discover',
    initialState,
    reducers: {
      setCurrentStyle: (state, action) => {
        state.currentStyle = action.payload
      },
      setSearchTerm: (state, action) => {
        state.searchTerm = action.payload
      }
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchDiscoverData.pending, (state) => {
            state.status = 'loading';
            state.ranks = null
            state.rankings = null
            state.topArtists = null
            state.topDates = null
            state.topPlaylists = null
          })
          .addCase(fetchDiscoverData.fulfilled, (state, action) => {
            state.status = 'idle'
            state.ranks = action.payload.rankings
            state.chart_styles = action.payload.chart_styles
            state.rankings = action.payload.rankings
            state.topDates = action.payload.topDates
            state.topPlaylists = action.payload.topPlaylists
          })
          .addCase(sendSearchAsync.pending, (state) => {
            state.status = 'loading';
            state.artistResults = null
            state.trackResults = null
          })
          .addCase(sendSearchAsync.fulfilled, (state, action) => {
            state.status = 'idle'
            state.artistResults = action.payload.res.artists
            state.trackResults = action.payload.res.tracks
          });
      },
});

export const { setCurrentStyle, setSearchTerm } = discoverSlice.actions;

export const musicStyles = (state) => state.discover.chart_styles
export const bestTracks = (state) => state.discover.best_tracks
export const selectNewTracks = (state) => state.discover.new_tracks
export const ranks = (state) => state.discover.rankings
export const topTenArtists = (state) => state.discover.topArtists
export const topTenProducers = (state) => state.discover.topProducers
export const topLabels = (state) => state.discover.topLabels
export const topDates = (state) => state.discover.topDates
export const topAlbums = (state) => state.discover.topAlbums
export const topPlaylists = (state) => state.discover.topPlaylists
export const selectCurrentStyle = (state) => state.discover.currentStyle
export const selectArtistResults = (state) => state.discover.artistResults
export const selectTrackResults = (state) => state.discover.trackResults
export const selectSearchTerm = (state) => state.discover.searchTerm

export default discoverSlice.reducer;
