import { Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import { selectSearchTerm, sendSearchAsync, setSearchTerm } from '../../../actions/discoverSlice'
import { useDispatch, useSelector } from 'react-redux'

const LeftItem = styled(Paper)(({ theme }) => ({
	backgroundColor: '#FFFFFF',
	padding: '32px',
	textAlign: 'center',
	color: theme.palette.text.secondary,
	borderRadius: '0',
}))
const WAIT_INTERVAL = 1000;

const SearchCard = () => {
	const dispatch = useDispatch()
	const [term, setTerm] = useState('')
	const searchedTerm = useSelector(selectSearchTerm)
	const [timer, setTimer] = useState(null);

	const onSearchTermChange = (event) => {
		setTerm(event.target.value)
		dispatch(setSearchTerm(event.target.value))
		if (timer) {
			clearTimeout(timer)
			setTimer(null)
		}
		setTimer(
			setTimeout(() => {
				dispatch(sendSearchAsync(event.target.value))
			}, WAIT_INTERVAL)
		);
	}

	const cancelAction = () => {
		setTerm('')
		dispatch(setSearchTerm(''))
	}
		
	return (
			<LeftItem sx={{height: '8vh'}} >
				<TextField key='search' 
					id='search_field' 
					label='Rechercher' 
					variant='outlined'
					value={term}
					sx={{width: '85%', margin: '5%', color: 'white', border: 'none', backgroundColor: '#ffffff', borderRadius: '50px', '& .MuiOutlinedInput-root': {'& fieldset': {borderRadius: '50px', color: 'white'}}}} 
					onChange={onSearchTermChange}/>
					{
						searchedTerm
						?
							<Button color='red' onClick={cancelAction}>annuler</Button>
						: 
							''
					}
			</LeftItem>
		)
}

export default SearchCard