import { Button,TextField } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import React, { useState } from "react";
import API from "../../utils/API.js";
import { useNavigate } from "react-router";


const Register = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [checkPassword, setCheckPassword] = useState('')
    const navigate = useNavigate()

    const handleSignUp = async (e) => {
        navigate('/login')
    }
    
    const handleSubmit = async (e) => {
        if (!email || email.length === 0) {alert("pas de mail");} 
        else if (!password || password.length === 0 || password !== checkPassword) {alert("Les mots de passes ne correspondent pas");}
        else if (password && email) {
            try {
                const { data } = await API.signup({ email, password });
                localStorage.setItem("token", data.token);
                window.location = "/login";
            } catch (error) {
                console.error(error);
            }
        }
    } 

    const handleChange = (event) => {
        switch (event.target.id) {
            case 'email_field':
                setEmail(event.target.value)
                break;
            case 'password_field':
                setPassword(event.target.value)
                break;
            case 'password_check_field':
                setCheckPassword(event.target.value)
                break;
            default:
                break;
        }
    }

    return (

            <Grid2 xs={2} xsOffset={2}
            component="form"
            sx={{
                fontFamily: 'Montserrat',
                '& > :not(style)': { m: 1 },
                '& .MuiTextField-root': { m: 1, width: '80%' },
                '& .MuiInputBase-input': { color: 'white' },
                position: "absolute",
                top: "50%",
                color: "white",
                transform: "translate(50%, -50%)",
                textAlign: "center"
            }}>
                <h2 >Inscription</h2>
                <hr></hr>
                <TextField focused key={'email_field'} id='email_field' label='Email' variant='outlined' defaultValue={''} sx={{width: '80%!important', '& .MuiInputBase-input': { color: 'white!important' },}} color={'white'} onChange={handleChange}/>
                <TextField focused key={'password_field'} id='password_field' type={'password'} label='Mot de passe' variant='outlined' defaultValue={''} color={'white'} sx={{width: '80%!important', '& .MuiInputBase-input': { color: 'white!important' },}} onChange={handleChange}/>
                <TextField focused key={'password_check_field'} id='password_check_field' type={'password'} label='Vérification du mot de passe' variant='outlined' color={'white'} defaultValue={''} sx={{width: '80%!important', '& .MuiInputBase-input': { color: 'white!important' },}} onChange={handleChange}/>
                <hr></hr>
                <Button onClick={handleSignUp} variant="text" endIcon={<PersonAddAlt1Icon />} color="white">
                    J'ai déjà un compte
                </Button>
                <Button onClick={handleSubmit} variant="outlined" endIcon={<SendIcon />} color="white">
                    Envoyer
                </Button>
            </Grid2>
    )
    
}
  


export default Register