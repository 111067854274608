import React, { useEffect, useRef } from 'react';

const useHorizontalScroll = () => {
    const elRef: React.RefObject<HTMLDivElement> | any  = useRef();
    useEffect(() => {
      const el: any = elRef.current;
      if (el !== undefined) {
        const onWheel = (e: any) => {
          if (e.deltaY === 0) return;
          e.preventDefault();
          el.scrollBy(e.deltaY, 0);
        };
        el.addEventListener("wheel", onWheel);
        return () => el.removeEventListener("wheel", onWheel);
      }
    }, []);
    return elRef;
}

export default useHorizontalScroll

