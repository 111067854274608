import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useGetArtistTopTracksQuery } from '../../../api/trackApiSlice';
import { Button, SxProps, Theme } from '@mui/material';
import { pause, playTrack } from '../../../services/MusicPlayerApi';
import withMK from '../../../hoc/withMK';
import { useMemo } from 'react';

interface PlayTopTracksButtonProps extends MKProps {
    artistId: string
    style: SxProps<Theme>
}

const PlayTopTracksButton = (props: PlayTopTracksButtonProps) => {
    const currentTrackId = props.mk && props.mk.instance.queue.currentItem ? props.mk.instance.queue.currentItem.id : null
    const playing = props.mk?.instance?.isPlaying

    const {
        data: topTracks,
    } = useGetArtistTopTracksQuery(props.artistId) 


    const isCurrentArtist = useMemo(() => topTracks && topTracks.map( (track: Track) => track.apple_id+'' ).includes(currentTrackId+''), [currentTrackId, topTracks])

    return topTracks && topTracks.length > 0 
        ? 
            playing && isCurrentArtist
                ? <Button onClick={() => pause()} sx={props.style}><PauseIcon htmlColor='white' sx={{fontSize: '3vmin'}}/></Button>
                : <Button onClick={() => playTrack(topTracks.map( (track: any) => new MusicKit.MediaItem({type: 'song', id: track.apple_id+''})), 0)} sx={props.style}><PlayArrowIcon htmlColor='white' sx={{fontSize: '3vmin'}}/></Button>
        : <Button sx={props.style}><PlayArrowIcon htmlColor='#ffffff30' sx={{fontSize: '3vmin'}}/></Button>
}

const bindings = {
    [MusicKit.Events.mediaItemDidChange]: 'mediaItem',
    [MusicKit.Events.queueItemsDidChange]: 'queueItems',
    [MusicKit.Events.queuePositionDidChange]: 'queuePosition',
    [MusicKit.Events.playbackStateDidChange]: 'playbackState',
}
  

export default withMK( PlayTopTracksButton, bindings )