import React, { CSSProperties } from 'react'
import { artworkForMediaItem } from '../../../utils/Utils'
import { Avatar, Button, Stack, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

export const QueueItemState = {
  Played: 0,
  Playing: 1,
  Queued: 2,
};

interface QueueItemProps {
  removeItemFunc: (queuPosition: number) => void;
  style?: CSSProperties | null;
  item: MusicKit.QueueItem;
}

const QueueItem = ({ style, item, removeItemFunc }: QueueItemProps) => {
  // const stateClass = item.queueState === QueueItemState.Playing ? 'playing' : '';

  return (
    <Stack justifyContent={'space-between'} direction={'row'} sx={{mt: 2, p:1, }} alignItems={'center'}>
      <Avatar variant='square' src={artworkForMediaItem(item as MusicKit.MediaItem, 50)} alt='' sx={{height: '5vh', width: '5vh'}}/>
      <Stack direction={'column'} sx={{ml: 1, width: '100%'}}>
        <Typography color={'white.main'} sx={{fontWeight: 700}}>{item.attributes.name}</Typography>
        <Typography color={'white.main'} sx={{ml: 0, fontWeight: 300}}>
          {item.attributes.artistName}
        </Typography>
        <Typography color={'white.main'} sx={{ml: 0, fontWeight: 100, fontStyle: 'italic'}}>
          {item.attributes.albumName}
        </Typography>
      </Stack>
      <Stack >
        <span className={'removeButton'}>
          {item.queueState === QueueItemState.Queued && (
            <Button
              className={'fas fa-times notSortable'}
              onClick={() => removeItemFunc(item.queuePosition)}
            ><ClearIcon htmlColor='white' /></Button>
          )}
        </span>
      </Stack>
    </Stack>
  );
};

export default (QueueItem);
