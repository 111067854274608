import React, { ReactNode, useEffect, useState } from 'react';
import withMK from '../../hoc/withMK';
// import SplashScreen from '../../routes/SplashScreen/SplashScreen';
// import { useLocation } from 'react-router-dom';
import { Skeleton } from '@mui/material'

export const AuthorizeContext = React.createContext({ authorized: false });

interface AuthorizeProviderProps extends MKProps {
  children: ReactNode;
}

// interface AuthorizeProviderState {
//   ready: boolean;
//   browsing: boolean;
//   isAuthorized: boolean;
// }

const AuthorizeProvider = (props: AuthorizeProviderProps ) => {
    // const location = useLocation()
    // const allowDirectBrowse = /^\/(?!(me|$)).*$/i.test(location.pathname)
    const [ready, setReady] = useState(false)
    // const [browsing, setBrowsing] = useState(allowDirectBrowse)
    const [isAuthorized, setIsAuthorized] = useState(props.mk.instance ? props.mk.instance.isAuthorized : false)
    
    useEffect(() => {
        handleTokenCheck()

        const handler = check as () => void
        // console.log(MusicKit.getInstance())
        MusicKit.getInstance()?.addEventListener(MusicKit.Events.authorizationStatusDidChange, handler)
    // eslint-disable-next-line 
    }, [])
    

    const check = ({ authorizationStatus }: { authorizationStatus: number }) => {
        console.log('cheking')
        if (authorizationStatus === 0) {
            setIsAuthorized(false)
        } else {
            setTimeout(() => {
                setIsAuthorized(true)
            }, 0)
        }
    }

    const handleTokenCheck = async () => {
        const music = props.mk.instance

        if (isAuthorized) {
            setReady(true);
            return;
        }

        try {
            // ¯\_(ツ)_/¯ I have no clue why this works like that but it works soooo...
            // @ts-ignore: expect (ids: string[] | null, parameters?: QueryParameters)
            await music.api.library.songs({ limit: 0 })
        } catch (e) {
            await music?.unauthorize();
        }

        setTimeout(() => {
            setReady(true)
        }, 0)
    }

    // const startBrowsing = () => {
    //     setBrowsing( true )
    // }

    if (!ready) {
        console.log('AuthorizeProvider - waiting')
        return <Skeleton />
    }

    // if (!isAuthorized && !browsing) {
    //   return (
    //     <SplashScreen
    //       onClick={() => props.mk.instance?.authorize()}
    //       onBrowse={() => startBrowsing()}
    //     />
    //   )
    // }
    console.log('AuthorizeProvider')

    return (
      <AuthorizeContext.Provider value={{ authorized: isAuthorized }}>
        {props.children}
      </AuthorizeContext.Provider>
    )
}

export default withMK(AuthorizeProvider)
