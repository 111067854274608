import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material'


const SubscribeAMPopup = (props) => {

    const goToSubscibe = async () => {
        props.handleClose()
    }

    return <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"Vous avez un compte Apple Music ?"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Nous utilisons leur service pour vous permettre d'écouter les musiques.
                Sans compte abonné à <b>Apple Music</b>, l'écoute sera limitée à 30 secondes

            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={props.handleClose}>Non</Button>
            <Button onClick={goToSubscibe} autoFocus>
                Je me connecte !
            </Button>
            </DialogActions>
        </Dialog>
         
}


export default SubscribeAMPopup