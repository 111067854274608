import React from 'react'
import { Stack, Chip, Skeleton, Grid } from '@mui/material'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router'
import { useGetAlbumQuery } from '../../api/albumApiSlice';
import AddPlaylistDialog from './dialog/AddPlaylistDialog';
import AlbumSideMenu from './components/board/AlbumSideMenu';
import SubscribePopup from '../artist/dialog/SubscribePopup';

const chipStyle = { 
    color:'#fff',
    height: '2.6vh',
    borderRadius: '1.3vh',
    fontFamily: 'Montserrat',
    fontSize: '.5vw',
    marginRight: '18px',
    marginBottom: '18px',
    width: '10vw',
    background: '#00000080',
    '& span': {padding: '0 18px'}
}

const AlbumBoard = () => {
    let { id } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const [open, setOpen] = React.useState(false)

    const {
        data: albumLiked
    } = useGetAlbumQuery({id})

    const showTracks = () => {
        navigate('tracks')
    }

    const showTeam = () => {
        navigate('team')
    }

    const showStats = () => {
        navigate('stats')
    }

    const handleClickOpen = () => {
        setOpen(true)
    }
    
    const handleClose = () => {
        setOpen(false)
    }


    return albumLiked 
    ?
        <Grid container spacing={2} sx={{height: '100%'}}>
            <AddPlaylistDialog />
            <SubscribePopup handleClose={handleClose} open={open} />
            <AlbumSideMenu handleClickOpen={handleClickOpen} />
            <Grid item lg={8} xs={12} sx={{height: '100%', position: 'relative', borderRadius: '16px', }}>
                <Stack direction={'column'} alignItems={'center'} sx={{borderRadius: '16px', width:'100%', height: '100%', backdropFilter: 'blur(40px)  brightness(70%)'}}>
                    <Stack direction={'row'} sx={{height: '3vh', overflowY: 'hidden', overflowX: 'hidden', margin: '24px', position: 'relative', zIndex: 1000}}>
                        <Chip key={'Tracks'} label={'Tracks'} variant={!location.pathname.includes('team') && !location.pathname.includes('stats') ? 'outlined' : 'filled' } onClick={showTracks}sx={chipStyle}/>
                        <Chip key={'Équipe'} label={'Équipe'} variant={location.pathname.includes('team')? 'outlined' : 'filled' } onClick={showTeam} sx={chipStyle}/>
                        <Chip key={'Statistiques'} label={'Statistiques'} variant={location.pathname.includes('stats') ? 'outlined' : 'filled' } onClick={showStats} sx={chipStyle}/>
                    </Stack>
                    <Outlet />
                </Stack>
            </Grid>   
        </Grid>
    : 
        <Skeleton />
}




export default AlbumBoard