import React from 'react';
import withMK from '../../../hoc/withMK';
import translate from '../../../utils/translations/Translations';
import { QueueModalProps, withQueueModal } from '../../../core/providers/QueueProvider';
import QueueList from './QueueList';
import { Modal, Button, Box, Typography, Stack } from '@mui/material';

type QueueProps = QueueModalProps & MKProps;

class Queue extends React.Component<QueueProps> {
  public static shouldCancelStart(e: React.MouseEvent<any, MouseEvent>) {
    return (e.target as Element).classList.contains('notSortable');
  }

  public onSortEnd = ({ oldIndex, newIndex }: { oldIndex: any; newIndex: any }) => {
    const {
      mk: {
        instance: { player },
      },
    } = this.props;

    if (oldIndex !== newIndex) {
      const { items, position } = player.queue;

      const newOldIndex = oldIndex + position;
      const newNewIndex = newIndex + position;

      // Update queue order
      items.splice(newNewIndex, 0, items.splice(newOldIndex, 1)[0]);

      // @ts-ignore
      player.queue._reindex();

      this.forceUpdate();
    }
  };

  public removeItem = (index: any) => {
    const { queue } = this.props.mk?.instance

    // Update queue order
    queue.items.splice(index, 1);

    // @ts-ignore
    queue._reindex();

    this.forceUpdate();
  };

  public render() {
    const { queueModal } = this.props;

    if (!queueModal.isOpen) {
      return null;
    }

    return (
        <Modal open={queueModal.isOpen} className={'modal'} onClick={e => e.stopPropagation()} onClose={queueModal.close}>
          <Box sx={{width: '20vw', m: '5% auto', background: '#08082F', borderRadius: '16px' }}>
          <div className={'header'}>
            <Stack justifyContent={'space-between'} direction={'row'} className={'title'} sx={{p: 2}}>
              <Typography color={'white.main'}>{`${translate.upNext}`}</Typography>
              <Button onClick={queueModal.close} color={'secondary'}>Fermer</Button>

            </Stack>
            
          </div>
          <Box sx={{height: '60vh', overflow: 'hidden', overflowY: 'scroll'}}>
            <QueueList
              removeItemFunc={this.removeItem}
            />
          </Box>
          </Box>
        </Modal>
    );
  }
}

export default withMK(withQueueModal(Queue));
