import { Link, useParams } from 'react-router-dom'
import { Avatar, Button, Stack, Typography, Grid } from '@mui/material';
import { song_producers, song_title, artist_name } from './Player.module.css';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import SpeakerIcon from '../../assets/SpeakerIcon';
import RandomIcon from '../../assets/randomIcon';
import AlbumIcon from '../../assets/AlbumIcon';
import withMK from '../../hoc/withMK';
import { artworkForMediaItem } from '../../utils/Utils';
import { pause, play, skipToNext, skipToPrevious } from '../../services/MusicPlayerApi';
import { useGetTrackByAppleIdQuery } from '../../api/trackApiSlice';
import { withQueueModal, QueueModalProps } from '../../core/providers/QueueProvider';
import { ModalProviderValue, withModal } from '../../core/providers/ModalProvider';


interface PlayerProps extends MKProps, QueueModalProps {
    authorized: boolean
    modal: ModalProviderValue
}

const Player = (props: PlayerProps) => {
    let { id } = useParams()
    
    const { 
        data: track, 
        isLoading 
    } = useGetTrackByAppleIdQuery(props.mk.instance.queue.currentItem && props.mk.instance.queue.currentItem.id != null ? props.mk.instance.queue.currentItem.id : '1693872275')  
    
    const playing = props.mk.instance.isPlaying
    
    const nowPlayingItem = props.mk.instance.queue.currentItem
    
    const artworkURL = artworkForMediaItem(nowPlayingItem, 60)
    
    return (
        <Grid item xs={12} md={10} lg={10}>
            <Grid container alignItems={'center'} spacing={0}>

                <Grid item xs={9} md={2} lg={2}>
                    <Stack direction={'row'} alignSelf={'center'} alignContent={'center'} alignItems={'center'}>
                        <Button sx={{width: '100%', '& svg': {fontSize: {xs: '4.5vmin', md: '1.5vmin', lg: '1.5vmin'}}}} onClick={props.queueModal.isOpen ? props.queueModal.close : props.queueModal.open}><AlbumIcon color='#fff' /></Button>
                        <Button sx={{width: '100%', '& svg': {fontSize: {xs: '4.5vmin', md: '1.5vmin', lg: '1.5vmin'}}}}><RandomIcon color='#fff' /></Button>
                        <Button sx={{width: '100%', '& svg': {fontSize: {xs: '4.5vmin', md: '1.5vmin', lg: '1.5vmin'}}}} onClick={() => props.mk.instance.authorize()} ><SpeakerIcon color='#fff' /></Button>
                    </Stack>
                </Grid>

                <Grid item xs={3} md={1} lg={1}>
                    <Stack spacing={0} direction="row" alignContent={'center'} alignItems={'center'} alignSelf={'center'} sx={{'& apple-music-volume' :{ width: '100%'}}}>
                        <apple-music-volume theme="dark"></apple-music-volume>
                    </Stack>
                </Grid>   

                <Grid item xs={12} md={6} lg={6}>
                    <Stack spacing={0} sx={{ mb: 1, width: '100%' }} alignItems="left">
                        <Stack spacing={0} direction="row" sx={{ mb: 0, padding: '0', margin: '0', height: '3px' }} alignItems="center">
                            <Typography sx={song_title} >{
                                track && track.apple_title
                                ? <Link to={`/track/${track._id}`} style={{textDecoration: 'none'}}>{track.apple_title}</Link>
                                : '--'
                            }</Typography>
                            <Typography sx={artist_name} >
                            {
                                track && !isLoading
                                ? 
                                track.main_artists?.map( (artist: Artist, i: number) =>  {
                                    return track.main_artists && i === track.main_artists?.length - 1
                                    ? artist._id === id ? artist.artist_name : <Link key={i + artist._id} to={`/artist/${artist._id}`} >  { artist.artist_name } </Link>
                                    : artist._id === id ? artist.artist_name + ', ' : <span key={i + artist._id}><Link key={i + artist._id} to={`/artist/${artist._id}`} > { artist.artist_name } </Link>, </span>
                                })
                                
                                : '--'
                            }
                            </Typography>
                            { track && track.featured_artists && track.featured_artists.length > 0
                                ?
                                <>
                                <Typography sx={{...artist_name, }}>&</Typography>
                                <Typography sx={artist_name} >
                                {
                                    track && !isLoading
                                    ? 
                                    track.featured_artists?.map( (artist: Artist, i: number) =>  {
                                        return track.featured_artists && i === track.featured_artists?.length - 1
                                        ? artist._id === id ? artist.artist_name : <Link key={i + artist._id} to={`/artist/${artist._id}`} >  { artist.artist_name } </Link>
                                        : artist._id === id ? artist.artist_name + ', ' : <span key={i + artist._id}><Link key={i + artist._id} to={`/artist/${artist._id}`} > { artist.artist_name } </Link>, </span>
                                    })
                                    
                                    : '--'
                                }
                                </Typography>
                                </>
                                : ''
                            }
                        </Stack>
                        <Stack spacing={0} direction="row" sx={{ mb: 1, padding: '0', margin: '0', '& apple-music-progress' :{ width: '100%', marginTop: '16px', padding: '0 8px'}}} alignItems="center" >
                            <apple-music-progress theme="dark"></apple-music-progress>
                        </Stack>
                        <Stack spacing={0} direction="row" sx={{ mb: 1, padding: '0', margin: '0', height: '3px', position: 'relative' }} alignItems="center">
                            <Typography sx={song_producers} >{
                                track && !isLoading
                                ? 
                                track.producers?.map( (artist: Artist, i: number) =>  {
                                    return track.producers && i === track.producers?.length - 1
                                    ? artist._id === id ? artist.artist_name : <Link key={i + artist._id} to={`/artist/${artist._id}`} >  { artist.artist_name } </Link>
                                    : artist._id === id ? artist.artist_name + ', ' : <span key={i + artist._id}><Link to={`/artist/${artist._id}`} > { artist.artist_name } </Link>, </span>
                                })
                                
                                : '--'
                            }</Typography>
                        </Stack>
                    </Stack>
                </Grid>

                <Grid item lg={3} md={3} xs={12} alignSelf={'center'} alignContent={'center'} alignItems={'center'}>
                    <Stack direction={'row'}>
                        <Button sx={{width: '100%', display: {xs: 'block', md: 'block', lg: 'block'}, fontSize: {xs: '4vmin', md: '2vmin', lg: '2vmin'}}} onClick={() => skipToPrevious()}><SkipPreviousIcon htmlColor='white' sx={{fontSize: {xs: '4vmin', md: '2vmin', lg: '2vmin'}, p:0 }}/></Button>
                        {
                            playing
                            ? <Button sx={{width: '100%'}} onClick={() => pause()}><PauseIcon htmlColor='white' sx={{fontSize: {xs: '4vmin', md: '2vmin', lg: '2vmin'}, p:0 }}/></Button>
                            : <Button sx={{width: '100%'}} onClick={() => play()}><PlayArrowIcon htmlColor='white' sx={{fontSize: {xs: '4vmin', md: '2vmin', lg: '2vmin'}, p:0 }}/></Button>
                        }
                        <Button sx={{width: '100%', display: {xs: 'block', md: 'block', lg: 'block'}}} onClick={() => skipToNext()}><SkipNextIcon htmlColor='white' sx={{fontSize: {xs: '4vmin', md: '2vmin', lg: '2vmin'}, p:0 }}/></Button>
                        <Avatar variant='square' src={artworkURL} className={'image_cover'} alt="placeholder cover"><MusicNoteIcon/></Avatar>
                    </Stack>
                </Grid>           

            </Grid>
        </Grid>
    );
}

const bindings = {
    [MusicKit.Events.mediaItemDidChange]: 'mediaItem',
    [MusicKit.Events.queueItemsDidChange]: 'queueItems',
    [MusicKit.Events.queuePositionDidChange]: 'queuePosition',
    [MusicKit.Events.playbackStateDidChange]: 'playbackState',
}

export default withMK(
    withQueueModal(withModal( Player )), 
    bindings
)