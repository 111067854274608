import { useState } from 'react'
import placeholder from '../../../../assets/placeholder_artist.jpg'
import { Box, Skeleton } from '@mui/material'
import { useGetAlbumsQuery } from '../../../../api/discoverApiSlice'
import { selectCurrentStyle } from '../../../../actions/discoverSlice'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styles from './AlbumsFocus.module.css'
import AlbumFocusHover from './AlbumFocusHover'

const AlbumsFocus = () => {
    const selectedStyle = useSelector(selectCurrentStyle)
    var [currentIndex, setCurrentIndex] = useState(0) 

    const {
        data: albums,
        isFetching,
        isLoading
    } = useGetAlbumsQuery(selectedStyle)

    return albums && !isFetching && !isLoading && albums.length > 0
    ?
        <Box className={styles.albumItem} >
            <AlbumFocusHover albums={albums} currentIndex={currentIndex} action={setCurrentIndex} />
            <Link to={`/album/${albums[currentIndex]._id}`}>
                <img 
                    className="image-cover"
                    width={'100%'}
                    height={'100%'}
                    src={albums[currentIndex].apple_artwork_url ? albums[currentIndex].apple_artwork_url.replace("{w}","320").replace("{h}","320") : placeholder} 
                    alt={albums[currentIndex].apple_name} />
            </Link>
        </Box>
    :
        <Skeleton variant="rectangular" width={'50%'} height={'100%'} sx={{bgcolor: '#00000088'}} />

}

export default AlbumsFocus