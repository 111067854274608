import React, { CSSProperties, ReactNode } from 'react';


interface ModalProps {
  handleClose: () => void;
  style?: CSSProperties;
  width?: number;
  render: () => ReactNode;
}

function Modal(props: ModalProps) {
  const { style = {}, width } = props;
  const inlineStyles = {
    width,
    ...style,
  };

  return (
    <div className={'container'} onClick={props.handleClose}>
      <div className={'modalWrapper'}>
        <div className={'modal'} onClick={e => e.stopPropagation()} style={inlineStyles}>
          {props.render()}
        </div>
      </div>
    </div>
  );
}

export default Modal;
