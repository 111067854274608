import { apiSlice } from "./apiSlice"
import API from "../utils/API";

export function getInitialData(style = 34) {
    return API.getHome(style)
    .then(res => {
        return res.data
    })
    .catch(err => console.log(err))
}

export function getDiscoverData(style = 34) {
    return API.getDiscover(style)
    .then(res => {
        return res.data
    })
    .catch(err => console.log(err))
}

export function getSearchByTerm(term) {
    return API.getSearch(term)
    .then(res => {
        return res.data
    })
    .catch(err => console.log(err))
}


export const discoverApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getStyles: builder.query({
            query: () => `/styles`,
        }),
        getProducers: builder.query({
            query: (style = 34) => `/v1/discover/top/producers/${style}`,
        }),
        getInterpretors: builder.query({
            query: (style = 34) => `/v1/discover/top/interpretors/${style}`,
        }),
        getAlbums: builder.query({
            query: (style = 34) => `/v1/discover/top/albums/${style}`,
        }),
        getNewTracks: builder.query({
            query: (style = 34) => `/v1/discover/top/news/${style}`,
        }),
        getBestsTracks: builder.query({
            query: (style = 34) => `/v1/discover/top/bests/${style}`,
        }),
        getLabels: builder.query({
            query: (style = 34) => `/v1/discover/top/labels/${style}`,
        }),
        getPlaylists: builder.query({
            query: (style = 34) => `/v1/discover/top/playlists/${style}`,
        })
    })
})


export const {
    useGetProducersQuery,
    useGetInterpretorsQuery,
    useGetAlbumsQuery,
    useGetNewTracksQuery,
    useGetLabelsQuery,
    useGetStylesQuery,
    useGetBestsTracksQuery,
    useGetPlaylistsQuery
} = discoverApiSlice 