import React from "react";

function RandomIcon({color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      flex-shrink="0"
      fill="none"
      viewBox="0 0 19 15"
    >
      <path
        fill={color}
        d="M16.951 10.938c-.376-.375-.723-.72-1.068-1.066-.07-.07-.142-.138-.2-.216a.563.563 0 01.051-.749.57.57 0 01.764-.08c.062.045.118.1.172.155l2.042 2.04c.365.365.363.708-.004 1.075l-2.085 2.085c-.181.182-.396.247-.636.16a.577.577 0 01-.397-.523c-.018-.205.078-.363.219-.502.33-.326.656-.654.984-.983.049-.048.094-.1.178-.19-.147 0-.248-.002-.35 0-1.069.017-2.131-.017-3.176-.293-1.797-.475-3.337-1.38-4.598-2.743-.772-.835-1.495-1.717-2.287-2.533-1.443-1.487-3.214-2.318-5.286-2.494a12.143 12.143 0 00-.609-.033C.283 4.035.018 3.79.017 3.446c0-.342.27-.593.655-.591 1.968.011 3.753.598 5.367 1.717.797.553 1.457 1.25 2.094 1.974.603.685 1.19 1.387 1.84 2.025 1.592 1.555 3.522 2.334 5.747 2.378.372.008.745.001 1.117.001.02 0 .042-.003.116-.011l-.002-.001z"
      ></path>
      <path
        fill={color}
        d="M16.912 2.823l-1.037-1.036c-.07-.07-.141-.139-.199-.217a.565.565 0 01.077-.763.567.567 0 01.75-.052c.056.04.107.09.156.14l2.07 2.07c.362.362.36.651 0 1.013-.694.695-1.388 1.39-2.085 2.084-.238.237-.497.285-.742.146-.358-.203-.425-.638-.132-.946.334-.35.683-.687 1.025-1.03.048-.047.092-.099.147-.159-2.875-.213-5.349.596-7.345 2.747l-.825-.879c2.218-2.33 4.952-3.266 8.127-3.053l.013-.064zM6.776 8.145l.823.927a9.48 9.48 0 01-1.783 1.52c-1.559 1.025-3.274 1.543-5.137 1.576-.367.007-.626-.195-.672-.505-.054-.379.191-.664.606-.698.39-.032.786-.035 1.174-.093 1.898-.282 3.507-1.132 4.836-2.514.052-.054.09-.125.152-.214h.001z"
      ></path>
    </svg>
  );
}

export default RandomIcon;
