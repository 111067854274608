import { List, ListItem, Box  } from '@mui/material'
import TrackRow from '../../track/TrackRow';
import useHorizontalScroll from '../../../utils/HorizontalScroll';

const ArtistGraphMainCollabsList = ({role}) => {

    const scrollRef = useHorizontalScroll()

    return (
        <List 
            ref={scrollRef} 
            direction={'column'} 
            sx={{
                height: '15vh',
                margin: '0 auto', 
                width: '100%',
                overflow: 'hidden',
                overflowX: 'scroll', 
                columns: role && role.tracks && role.tracks.length <= 2 ? 1 : 2, 
                mb: 1
            }}
        >
        { 
            role && role.tracks 
                ? [...role.tracks].sort((a, b) => new Date(b.apple_release_date) - new Date(a.apple_release_date)).map( (track, i) => <ListItem key={i} sx={{height: '50%'}} ><TrackRow track={track} index={i} /></ListItem>)
                : <ListItem><Box sx={{height: '2vh'}}></Box></ListItem>
        }
        </List>
    )
                                
}

export default ArtistGraphMainCollabsList