import { Avatar, Button, Skeleton, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ShareIcon from '@mui/icons-material/Share'
// import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import PauseIcon from '@mui/icons-material/Pause';
import { setAddPlaylistTrack } from '../../../actions/artistSlice'
import { useLikeByTypeMutation } from '../../../api/userApiSlice'


const TracksGroupedByDate = (props) => {
    let { id } = useParams()
    const dispatch = useDispatch()
    const [likeTrack] = useLikeByTypeMutation()
    const playingState = null
    const currentTrackId = null

    const  tracks = props.tracks

    const showPlaylistPopup = async (track) => {
        dispatch(setAddPlaylistTrack({track: track}))
    }

    const likeTrackById = async (ID) => {
        try {
           await likeTrack({target_id: ID, type: 'track'})
        } catch (err) {
            console.log(err)
        }
    }

    const playTrack = async (track) => {
        // if (amAuthToken === null)
        //     await dispatch(authAppleMusicAsync())

        // await dispatch(addTrackToQueueAsync({track}))
        // dispatch(playTrackAsync())
    }

    const pause = async () => {
        // dispatch(pauseTrackAsync())
    }

    return tracks && tracks !== null 
    ?  tracks.map( (track, i) => {
            return (
                    <Stack  key={Date.now() + i + track._id} sx={{position: 'relative'}} >
                        <Stack direction="row">
                            <Typography sx={{lineHeight: 5, margin: '0 10px', width: '2vw'}} ><i>-{i+1}-</i></Typography>
                            <Avatar variant="square" alt={track.album.apple_name} src={track.album.apple_artwork_url.replace("{w}","100").replace("{h}","80") } sx={{width: '6vh', height: '6vh'}} />
                            <Stack sx={{display: 'inline-block', marginLeft: '16px', width: '55vw'}}>
                                <Typography>{track.apple_title }</Typography>
                                <Stack direction="row">
                                    <Typography>{track.main_artists.map( (artist, i) =>  {
                                        return i === track.main_artists.length - 1
                                            ? artist._id === id ? artist.artist_name : <Link key={Date.now() + i + artist._id} to={`/artist/${artist._id}`} > { artist.artist_name } </Link>
                                            : artist._id === id ? artist.artist_name + ', ' : <span key={Date.now() + i + artist._id}><Link key={Date.now() + i + artist._id} to={`/artist/${artist._id}`} > { artist.artist_name } </Link>, </span>
                                        }
                                        )}
                                    </Typography>
                                    {
                                        track.featured_artists.length > 0
                                        ?
                                            <Typography sx={{marginLeft: '4px'}}>ft. {track.featured_artists.map( (artist, i) =>  {
                                                return i === track.featured_artists.length - 1
                                                    ? artist._id === id ? artist.artist_name : <Link key={Date.now() + i + artist._id} to={`/artist/${artist._id}`} > { artist.artist_name } </Link>
                                                    : artist._id === id ? artist.artist_name + ', ' : <span key={Date.now() + i + artist._id}><Link  to={`/artist/${artist._id}`} > { artist.artist_name } </Link>, </span>
                                                }
                                                )}
                                            </Typography>
                                        : ''
                                    }
                                </Stack>
                            </Stack>
                            <Stack direction="row" sx={{marginLeft: '16px', right: '5%'}}>
                                {
                                    playingState && track.apple_id === currentTrackId
                                    ? <Button key={8} color="secondary" onClick={() => pause()} ><PauseIcon color='white'/></Button>
                                    : <Button key={8} color="secondary" onClick={() => playTrack(track)} ><PlayArrowIcon color='white'/></Button>
                                }
                                <Button key={7} color="secondary" onClick={() => showPlaylistPopup(track)}><PlaylistAddCheckIcon color='white'/></Button>
                                <Button key={6} color="secondary" onClick={() => likeTrackById(track._id)} ><FavoriteIcon color='white'/></Button>
                                <Button key={10} color="secondary"><ShareIcon color='white'/></Button>
                            </Stack>
                        </Stack>
                        <hr width='100%'/>
                    </Stack>
                )
            })
    : 
        <Skeleton variant="rectangular" width={'80vw'} height={'80vh'} />
}

export default TracksGroupedByDate