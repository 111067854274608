import { Button, Stack, Typography, Box, Avatar, Menu, Breadcrumbs, Modal, Grid } from '@mui/material'
import placeholder from '../../../../assets/placeholder_artist.jpg'
import '../../Album.module.css'
import React from 'react'
import AMBadgeListenIn from '../../../../assets/AMBadgeListenIn.svg'
import { useGetAlbumQuery } from '../../../../api/albumApiSlice'
import { Link, useParams } from 'react-router-dom'
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic'
import ShareIcon from '@mui/icons-material/Share'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import FavoriteIcon from '@mui/icons-material/Favorite'
import Pause from '@mui/icons-material/Pause'
import { pause, playAlbum } from '../../../../services/MusicPlayerApi'
import withMK from '../../../../hoc/withMK'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { useLikeByTypeMutation } from '../../../../api/userApiSlice'
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility'
import AlbumIcon from '@mui/icons-material/Album'
const parse = require('html-react-parser')


const style = {
    position: 'absolute',
    top: '40%',
    left: '31%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'primary.main',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    width: '22vw',
    color: '#fff'
  };

interface AlbumSideMenuProps extends MKProps {
    handleClickOpen: any
}

const AlbumSideMenu = (props: AlbumSideMenuProps) => {
    let { id } = useParams()
    const currentAlbumName = props.mk && props.mk.instance.queue.currentItem ? props.mk.instance.queue.currentItem.albumName : null
    const playing = props.mk?.instance?.isPlaying
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const openMenu = Boolean(anchorEl)
    const [likeAlbum] = useLikeByTypeMutation()
    const [open, setOpen] = React.useState(false)

    const handleOpen = () => {
      setOpen(true)
    }

    const handleClose = () => {
      setOpen(false)
    }
  
    const handleClickShare = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    const {
        data: albumLiked,
        refetch
    } = useGetAlbumQuery({id})


    const likeAlbumById = async (ID: string) => {
        try {
            const like = await likeAlbum({target_id: ID, type: 'album'}).unwrap()
            if(like) {
                console.log('TODO: Use like')
            }
            refetch()
        } catch (error) {
            props.handleClickOpen()
        }
    }
console.log(albumLiked)

    return (
        <Grid item lg={4} xs={12} sx={{height: '100%'}}>
            <Stack sx={{
                height: '100%', 
                borderRadius: '16px',
                overflow: 'hidden', 
                backgroundImage: `url(${albumLiked.album && albumLiked.album.apple_artwork_url ? albumLiked.album.apple_artwork_url.replace("cw.png","cc.png").replace('{h}', albumLiked.album.apple_artwork_width).replace('{w}', albumLiked.album.apple_artwork_width) : ''})`, 
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'}}
            >
                <Stack direction={'column'} sx={{backdropFilter: 'blur(20px)  brightness(70%)', width: '100%', height: '100%', position: 'relative', borderRadius: '16px'}} >

                    <Breadcrumbs aria-label="breadcrumb" sx={{ p: 2, color: 'white'}}>
                        <Link
                            style={{ display: 'flex', alignItems: 'center' }}
                            color="white"
                            to={"/artist/"+albumLiked.mainArtist._id}
                        >
                            <SettingsAccessibilityIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            {albumLiked.mainArtist.artist_name.length > 20 ? `${albumLiked.mainArtist.artist_name.slice(0, 19)}...` : albumLiked.mainArtist.artist_name}
                        </Link>
                        <Link
                            style={{ display: 'flex', alignItems: 'center' }}
                            color="white"
                            to={"/album/"+albumLiked.album._id}
                        >
                            <AlbumIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            {albumLiked.album.apple_name.length > 20 ? `${albumLiked.album.apple_name.slice(0, 19)}...` : albumLiked.album.apple_name}
                        </Link>
                    </Breadcrumbs>

                    <Stack direction="row" alignItems="center" sx={{ p: 8 , pt: 0, pb: 0}}>
                        <Avatar variant='square' alt={albumLiked.album.apple_artist_name} src={albumLiked.album.apple_artwork_url ? albumLiked.album.apple_artwork_url.replace("cw.png","cc.png").replace('{h}', albumLiked.album.apple_artwork_width).replace('{w}', albumLiked.album.apple_artwork_width) : placeholder} sx={{width: '100%', height:'100%', borderRadius: '16px'}} />
                    </Stack>

                    <Stack direction={'column'} alignItems={'center'}>
                        <Stack direction={'row'} alignContent={'center'} alignItems={'center'} alignSelf={'center'}  sx={{pb: 1, pt: 1}}>
                            {
                                playing && currentAlbumName === albumLiked.album.apple_name
                                ? <Button onClick={() => pause()} sx={{width: '6vh',p: 2, m: 2, ml: 0, borderRadius: '16px', backgroundColor: '#00000080'}}> <Pause htmlColor={'white'} fontSize='large' /> </Button>
                                : <Button onClick={() => playAlbum({ id: albumLiked.album.apple_id+''}, 0)} sx={{width: '6vh',p: 2, m: 2, ml: 0, borderRadius: '16px', backgroundColor: '#00000080'}}> <PlayArrowIcon htmlColor={'white'} fontSize='large' /> </Button>
                            }
                            <Button onClick={() => likeAlbumById(albumLiked.album._id)} sx={{p: 2, m: 2, ml: 0, borderRadius: '16px', backgroundColor: '#00000080'}}> <FavoriteIcon htmlColor={albumLiked.isFollowed ? '#FF5964' : '#ffffff'} fontSize='large' /> </Button>
                            <Button sx={{width: '6vh',p: 2, m: 2, ml: 0, borderRadius: '16px', backgroundColor: '#00000080'}}> <LibraryMusicIcon htmlColor={'white'} fontSize='large' /> </Button>
                            <Button onClick={handleClickShare} sx={{width: '6vh', p: 2, m: 2, ml: 0, borderRadius: '16px', backgroundColor: '#00000080'}}> <ShareIcon htmlColor={'white'} fontSize='large' /> </Button>
                            <Menu
                                id="share-menu"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                MenuListProps={{
                                'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleCloseMenu}
                                sx={{p: 0}}
                            >
                                <Button><FacebookIcon sx={{p: 2}}/> </Button>
                                <Button><TwitterIcon sx={{p: 2}}/> </Button>
                                <Button><InstagramIcon sx={{p: 2}}/> </Button>
                                <Button><WhatsAppIcon sx={{p: 2}}/> </Button>
                            </Menu>
                        </Stack>
                    </Stack>

                    <Typography variant='h3' sx={{fontWeight: 800, width: '100%', fontSize: '3vmin', pl: 2 }}>
                        {albumLiked.album.apple_artist_name}
                    </Typography>

                    <Typography className={'content'} sx={{ pl: 2 }}>
                    {
                        albumLiked.album.apple_notes && albumLiked.album.apple_notes.length > 0 
                        ? 
                        <Box sx={{ p: 0, m: 0}}>
                            <Grid container>
                                <Grid item xs={8}>
                                    <Button onClick={handleOpen} sx={{color: 'white!important', textDecoration: 'underline', textTransform: 'initial', p: 0 }} >Notes sur l'album</Button>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography sx={{pr: 2, textAlign: 'right'}}><i>{'Sorti le ' + (new Date(albumLiked.album.apple_release_date)).toLocaleDateString()}</i></Typography>
                                </Grid>
                            </Grid>
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                            <Box sx={style}>
                            {
                                parse(albumLiked.album.apple_notes)
                            }
                            </Box>
                            </Modal>
                        </Box>
                        : 
                            <Typography sx={{pr: 2, textAlign: 'right'}}><i>{'Sorti le ' + (new Date(albumLiked.album.apple_release_date)).toLocaleDateString()}</i></Typography>
                    }
                    </Typography>


                    <Grid sx={{ position: 'absolute', bottom: '16px', width: '100%', pl: 2}} >
                        <Grid xs={8}>
                        <Typography className={'credits'}>{albumLiked.album.record_label ? albumLiked.album.record_label.name.length > 120 ? albumLiked.album.record_label.name.slice(0, 119)+'...' : albumLiked.album.record_label.name : '--'}</Typography>                
                            <Typography className={'credits'}>{albumLiked.album.apple_copyright && albumLiked.album.apple_copyright.length > 120 ? albumLiked.album.apple_copyright.slice(0,119)+'...' : albumLiked.album.apple_copyright }</Typography> 
                        </Grid>
                        <Grid xs={4} sx={{textAlign: 'left', bottom: '16px', position: 'absolute', right: '16px'}}>
                            <a href={albumLiked.album.apple_url} target='blank'><img alt='apple music link' src={AMBadgeListenIn} /></a>                
                        </Grid>      
                    </Grid>

                </Stack>
            </Stack>
        </Grid>
  )
}


const bindings = {
    [MusicKit.Events.mediaItemDidChange]: 'mediaItem',
    [MusicKit.Events.queueItemsDidChange]: 'queueItems',
    [MusicKit.Events.queuePositionDidChange]: 'queuePosition',
    [MusicKit.Events.playbackStateDidChange]: 'playbackState',
}

export default withMK( AlbumSideMenu, bindings )