import { apiSlice } from "./apiSlice";

export const trackApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getTrack: builder.query({
            query: ({id, user_id}) => ({
                url: `/v1/tracks/${id}`,
                method: 'GET',
                params: {user_id}
            })  
        }),
        getTrackByAppleId: builder.query({
            query: (id) => ({
                url: `/v1/tracks/${id}/apple`,
                method: 'GET',
            })  
        }),
        getTrackStats: builder.query({
            query: (ids) => ({
                url: `/v1/tracks/${ids}/stats`,
                method: 'GET'
            })  
        }),
        getTracks: builder.query({
            query: (ids) => ({
                url: `/v1/tracks/multiple/${ids}`,
                method: 'GET'
            })  
        }),
        getArtistTopTracks: builder.query({
            query: (id) => ({
                url: `/tracks/artist/${id}/top`,
                method: 'GET'
            })  
        })
    })
})

export const {
    useGetTrackQuery,
    useGetTrackByAppleIdQuery,
    useGetTrackStatsQuery,
    useGetTracksQuery,
    useGetArtistTopTracksQuery
} = trackApiSlice