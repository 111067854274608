import React, { useEffect } from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material'
import { useNavigate } from 'react-router';


const SubscribePopup = (props) => {

    const navigate = useNavigate()

    useEffect(() => {

    })

    const goToSubscibe = () => {
        navigate('/register')
    }

    return <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"Merci de votre soutient !"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Vous pouvez creer un compte pour retrouver les artites et musiques que vous préférez !

            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={props.handleClose}>Plus tard</Button>
            <Button onClick={goToSubscibe} autoFocus>
                Je m'inscris !
            </Button>
            </DialogActions>
        </Dialog>
         
}




export default SubscribePopup