import React from "react";

function SpeakerIcon({color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="22"
      fill="none"
      viewBox="0 0 16 22"
    >
      <path
        fill={color}
        d="M0 10.993C0 8.115-.001 5.238.004 2.36.005 1.228.718.313 1.789.066c.214-.049.44-.063.66-.063C5.87 0 9.294 0 12.716.001c1.48.001 2.447.977 2.447 2.463v17.085c0 1.466-.973 2.448-2.433 2.449-3.432.003-6.864.003-10.295 0-1.459-.002-2.433-.986-2.434-2.45v-8.555H0zm14.422.032V2.572c0-.145-.002-.29-.018-.434-.083-.776-.718-1.38-1.495-1.381C9.358.75 5.808.753 2.257.756c-.412 0-.763.164-1.054.453C.838 1.571.74 2.023.74 2.52c.003 5.653.002 11.304.003 16.957 0 .119.002.24.014.358.081.804.719 1.407 1.524 1.408 3.533.006 7.068.005 10.601 0 .718 0 1.32-.484 1.486-1.18a2.67 2.67 0 00.052-.61c.002-2.809.002-5.618.002-8.427z"
      ></path>
      <path
        fill={color}
        d="M2.909 14.792c.006-2.62 2.094-4.73 4.677-4.73 2.595.003 4.685 2.134 4.67 4.762-.013 2.618-2.115 4.734-4.69 4.725-2.574-.01-4.663-2.143-4.657-4.757zm4.66 4c2.168.008 3.942-1.77 3.953-3.963.012-2.205-1.755-4.017-3.926-4.025-2.157-.009-3.943 1.785-3.952 3.971-.01 2.212 1.749 4.011 3.925 4.018zM10.981 5.897c.004 1.898-1.518 3.45-3.387 3.457-1.88.006-3.415-1.551-3.41-3.461.005-1.9 1.529-3.444 3.4-3.444 1.869 0 3.393 1.547 3.397 3.447zm-3.408 2.7c1.465.005 2.659-1.192 2.67-2.673.011-1.494-1.18-2.713-2.652-2.717C6.126 3.203 4.932 4.4 4.92 5.881c-.01 1.493 1.18 2.713 2.652 2.717z"
      ></path>
      <path
        fill={color}
        d="M7.58 12.574c1.219-.001 2.202.989 2.206 2.22.004 1.238-.996 2.251-2.214 2.245-1.209-.007-2.194-1.014-2.192-2.241a2.212 2.212 0 012.2-2.225zm.012 3.71a1.472 1.472 0 001.454-1.479c.003-.818-.67-1.499-1.473-1.492-.797.007-1.452.676-1.454 1.486a1.474 1.474 0 001.473 1.485z"
      ></path>
    </svg>
  );
}

export default SpeakerIcon;
