import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import Graph from 'react-graph-vis' 
import CloseIcon from '@mui/icons-material/Close'
import ArtistGraphMainCollabs from './ArtistGraphMainCollabs'
import ArtistGraphSecondaryCollabs from './ArtistGraphSecondaryCollabs'
// import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'
// import PianoIcon from '@mui/icons-material/Piano'
// import GroupIcon from '@mui/icons-material/Group' 
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import FavoriteIcon from '@mui/icons-material/Favorite'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import TrackRow from '../../track/TrackRow'

const ArtistGraph = ({id, graphKey, collabs, graphDatas, events, setNetwork, selectedCollab, handleCloseCollab}) => {

    const options = {
        autoResize: true,
        height: '100%',
        width: '100%',
        layout: {
            hierarchical: false,
            improvedLayout: true
        },
        interaction:{
            hover: true,
        },
        physics: {
            enabled: true,
            stabilization: {
                enabled: true,
                fit: true,
                iterations: 100
            },
            barnesHut: {
                theta: 0.5,
                gravitationalConstant: -5000,
                centralGravity: 0.3,
                springLength: 500,
                springConstant: 0.4,
                damping: 0.4,
                avoidOverlap: 0
            },
            maxVelocity: 50,
            minVelocity: 1,
            solver: 'barnesHut',
            adaptiveTimestep: true,
        },
        edges: {
            smooth: true,
            arrowStrikethrough: false,
        },
        nodes: {
            physics: true,
            
            font: {
                color: '#ffffff',
                face: 'Montserrat'
            }
        },
        groups: {
            useDefaultGroups: true,
            producer: {color:{background:'red'}, borderWidth:3},
            interpretor: {color:{background:'blue'}, borderWidth:3},
            featured: {color:{background:'green'}, borderWidth:3},
        }
    }


    return (
        <Box sx={{overflow: 'hidden', height: '100%', position: 'relative'}}>
            <Grid id={id} item xs={12} sx={{overflow: 'hidden', height: '100%', padding: '0', margin: '0', borderRadius: '16px'}}>
                <Graph
                    graphKey={graphKey}
                    graph={{
                        nodes: graphDatas.nodes,
                        edges: graphDatas.edges.filter(edge => edge.from !== edge.to )
                    }}
                    options={options}
                    events={events}
                    getNetwork={network => {
                        // console.log('SETTING NETWORK')
                        setNetwork(network)
                        //  if you want access to vis.js network api you can set the state in a parent component using this property
                    }}
                />
            </Grid>
            { selectedCollab 
                ? <Stack direction={'column'} sx={{position:'absolute', top: 0, background: '#17043d', width: '99%', border: '3px solid white', height: '99%', borderRadius: '16px', webkitClipPath: 'url(#scoop)', clipPath: 'url(#scoop)' }}>
                    <svg width='0' height='0'>
                        <defs>
                            <clipPath id='scoop' clipPathUnits='objectBoundingBox'>
                            <path d='M0.22,0 A0.15,0.19 0 0,1 0,0.28 
                                     L0,0.8 A0.2,0 0 0,1 0,1
                                     L1,1 A1,1 0 0,1 1,1
                                     L1,0.2 A0.2,1 0 0,1 1,0z' />
                            </clipPath>
                        </defs>
                    </svg>
                    <Stack sx={{position: 'relative', height: '100%'}}>
                        <Stack direction={'row'} >
                            <Grid xs={3}></Grid>
                            <Grid xs={7} sx={{p: 2}}>
                                <Typography sx={{pl: 1, fontSize: '3.8vmin', fontWeight: 700}} >{selectedCollab.artist.artist_name}</Typography>
                                <Typography sx={{pl: 3}} variant='caption'>{selectedCollab.artist.genre_names.join(' / ')}</Typography>
                            </Grid>
                            <Grid xs={2} sx={{p: 2, textAlign: 'right'}} >
                                <Button onClick={() => handleCloseCollab()}><CloseIcon sx={{color: '#fff', fontSize: '32pt', p: 0, margin: '0 auto',}}/></Button>
                            </Grid>
                        </Stack>
                        <Stack direction={'row'} >
                            <Grid xs={3}></Grid>
                            <Grid xs={9} sx={{pl: 2}}>
                                <Typography sx={{fontSize: '2vmin', fontWeight: 700, mr: 4, display: 'inline', verticalAlign: 'middle'}}><FavoriteIcon sx={{verticalAlign: 'middle', fontSize: '3vmin', ml: 2, mr: 2}} />5.8k</Typography> 
                                <Typography sx={{fontSize: '2vmin', fontWeight: 700, mr: 4, display: 'inline', verticalAlign: 'middle'}}><RemoveRedEyeIcon sx={{verticalAlign: 'middle', fontSize: '3vmin', ml: 2, mr: 2}} />12k</Typography> 
                                <Typography sx={{fontSize: '2vmin', fontWeight: 700, mr: 4, display: 'inline', verticalAlign: 'middle'}}><CalendarMonthIcon sx={{verticalAlign: 'middle', fontSize: '3vmin', ml: 2, mr: 2}} />16</Typography> 
                                <Typography sx={{fontSize: '2vmin', fontWeight: 700, mr: 4, display: 'inline', verticalAlign: 'middle'}}><PlayCircleOutlineIcon sx={{verticalAlign: 'middle', fontSize: '3vmin', ml: 2, mr: 2}} />24.6M</Typography> 
                            </Grid>
                        </Stack>
                        <Stack direction={'row'} sx={{pb: 4, pt: 2}}>
                            <Grid xs={3}></Grid>
                            <Grid xs={7} sx={{pl: 2, mt: 4}}>
                                <Box sx={{border: '1px solid white', borderRadius: '16px', p: 2, position: 'relative'}}>
                                    <Typography sx={{position: 'absolute', top: '-15px', background: '#17043d', pl: 2, pr: 2}}>Dernière sortie</Typography>
                                    <TrackRow track={selectedCollab.roles[0].tracks[0]} /> 
                                </Box>
                            </Grid>
                        </Stack>
                        <Grid xs={12} sx={{pl: 4, pr: 4, pb: 0, mt: 4, overflow: 'hidden', overflowY: 'scroll', position: 'inherit', bottom: 0, height: '68%'}}>
                            <ArtistGraphMainCollabs roles={selectedCollab.roles} />
                            <ArtistGraphSecondaryCollabs selectedCollab={selectedCollab}/>
                        </Grid>
                    </Stack>
                </Stack> 
                : ''
            }
        </Box>
    )
}

export default ArtistGraph