import { Stack, Grid } from '@mui/material'
import SearchCard from './elements/SearchCard'
import HistoryCard from './elements/HistoryCard'
import StylesCard from './elements/StylesCard'
//import FactsCard from './elements/FactsCard'
import ProducersFocus from './elements/producersFocus/ProducersFocus'
import LabelsFocus from './elements/LabelsFocus'
import AlbumsFocus from './elements/albumsFocus/AlbumsFocus'
import EventsFocus from './elements/EventsFocus'
import { PlaylistsFocus } from './elements/PlaylistsFocus'
import InterpretorsFocus from './elements/interpretorsFocus/InterpretorsFocus'
import BestTrackFocus from './elements/tracksFocus/BestTrackFocus'
import { useSelector } from 'react-redux'
import { selectSearchTerm } from '../../actions/discoverSlice'
import SearchCardResults from './elements/SearchCardResults'


export function DiscoverBoard() {

    const searchedTerm = useSelector(selectSearchTerm)

    return (
        <Grid container spacing={1} sx={{height: '100%', overflow: 'hidden', overflowY: 'scroll'}}>
            <Grid item lg={3} md={3} xs={0} sx={{height: '100%', display: { xs: 'none', md: 'flex', lg: 'flex'}}}>
                <Stack spacing={0} sx={{height: '100%', borderRadius: '10px', overflow: 'hidden'}} >
                    <SearchCard />
                    {
                        searchedTerm !== null && searchedTerm.length > 0
                        ?
                            <SearchCardResults /> 
                        :
                            <>
                                <HistoryCard />
                                <StylesCard />
                                {/* <FactsCard /> */}
                            </>
                    }
                </Stack>
            </Grid>
            <Grid item lg={4} md={4} xs={12} sx={{height: '100%'}}>
                <Stack spacing={1} sx={{height: '100%'}}>
                    <ProducersFocus />
                    <LabelsFocus />
                    <Stack direction="row" spacing={1} sx={{height: '30%'}}>
                        <AlbumsFocus />
                        <EventsFocus />
                    </Stack>
                </Stack>
            </Grid>
            <Grid item lg={5} md={5} xs={12} sx={{height: '100%'}}>
                <Stack direction={'column'} spacing={1} sx={{height: '100%'}}>
                    <Stack direction="row" spacing={1} sx={{height: '45%'}}>
                        <InterpretorsFocus />
                        <PlaylistsFocus />
                    </Stack>
                    <BestTrackFocus />
                </Stack>
            </Grid>
        </Grid>
    );
}











