import { apiSlice } from "./apiSlice"

export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUser: builder.query({
            query: () => '/users/me',
        }),
        getUserPlaylists: builder.query({
            query: () => '/users/me/playlists',
        }),
        createUserPlaylist: builder.mutation({
            query:  params => ({
                url: '/users/me/playlist/create',
                method: 'POST',
                body: { ...params}
            })
        }),
        addTarckUserPlaylist: builder.mutation({
            query:  params => ({
                url: `/users/me/playlist/add`,
                method: 'POST',
                body: { ...params}
            })
        }),
        likeByType: builder.mutation({
            query: params => ({
                url: `/users/me/like`,
                method: 'POST',
                body: { ...params}
            })  
        }),
        getUserArtistsFollowed: builder.query({
            query: () => `/users/me/likes/artists`
        })
    })
})


export const {
    useGetUserQuery,
    useGetUserPlaylistsQuery,
    useLikeByTypeMutation,
    useCreateUserPlaylistMutation,
    useAddTarckUserPlaylistMutation,
    useGetUserArtistsFollowedQuery
} = userApiSlice 