import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    artist: null,
    playlistTrack: null,
    status: 'idle',
};


export const artistSlice = createSlice({
    name: 'artist',
    initialState,
    reducers: {
      setArtist: (state, action) => {
        const { artist } = action.payload
        state.artist = artist
      },
      setAddPlaylistTrack: (state, action) => {
        const { track } = action.payload
        state.playlistTrack = track
      }
    }
});

export const { setArtist, setAddPlaylistTrack } = artistSlice.actions;

export const selectPlaylistTrack = (state) => state.artist.playlistTrack;


export default artistSlice.reducer;
