import { Typography } from '@mui/material';
import React from 'react';

const NotFound = () => {
    
    return (
        <Typography>
            404 - Tu vas où ?
        </Typography>
    )
}

export default NotFound