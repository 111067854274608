import { Avatar, Box, Button, Skeleton } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { useSelector } from 'react-redux'
import Paper from '@mui/material/Paper'
import styles from '../Discover.module.css'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import placeholder from '../../../assets/placeholder_artist.jpg'
import { Stack } from '@mui/system'
import { selectRecentArtists } from '../../../actions/mainSlice'
import { useGetArtistsQuery } from '../../../api/artistApiSlice'
import PersonOffIcon from '@mui/icons-material/PersonOff'

const LeftItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#FFFFFF',
    padding: '32px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: '0',
    borderTop: '1px solid #08082F',
}))

const HistoryCard = () => {
    const recentArtists = useSelector(selectRecentArtists)

    const {
        data,
        isLoading
    } = useGetArtistsQuery(recentArtists && recentArtists.length > 0 ? recentArtists : ['none'])

    return (
        <LeftItem sx={{height: '20vh', textAlign: 'left'}}>
            <h3 className={styles.title} variant="h3" >Récemment</h3>
            <Button className={styles.show_all}>Voir tout</Button>
            <Grid2 container spacing={2} sx={{height: '100%', padding: '0', margin: '0', overflow: 'hidden'}}>
                {
                    data && !isLoading
                    ? data.length > 0 
                        ? 
                            data.slice(0,6).map( artist => 
                                <Grid2 key={artist._id} xs={4}>
                                    <Link to={`artist/${artist._id}`}>
                                        <Avatar src={artist.avatar_url ? artist.avatar_url.replace("cw.png","cc.png").replace("{w}","600").replace("{h}","400") : placeholder} sx={{height: '8vh', width: '8vh'}} />
                                    </Link>
                                </Grid2>
                            )
                        :
                        <Stack alignSelf={'center'} alignContent={'center'} alignItems={'center'} sx={{width: '100%', pt: 2}}>
                            {/* <Typography>Les artites consultés récemment</Typography> */}
                            <PersonOffIcon sx={{fontSize: '60pt', color: '#08082f'}}/>
                        </Stack>
                    : 
                        <Box sx={{display: 'inline-block'}}>
                            <Stack direction={'row'}>
                                <Skeleton variant="circular" width={'8vh'} height={'8vh'} sx={{marginRight: '40px', marginBottom: '16px'}} />
                                <Skeleton variant="circular" width={'8vh'} height={'8vh'} sx={{marginRight: '40px', marginBottom: '16px'}} />
                                <Skeleton variant="circular" width={'8vh'} height={'8vh'} sx={{marginRight: '40px', marginBottom: '16px'}} />
                            </Stack>
                            <Stack direction={'row'}>
                                <Skeleton variant="circular" width={'8vh'} height={'8vh'} sx={{marginRight: '40px', marginBottom: '24px'}} />
                                <Skeleton variant="circular" width={'8vh'} height={'8vh'} sx={{marginRight: '40px', marginBottom: '24px'}} />
                                <Skeleton variant="circular" width={'8vh'} height={'8vh'} sx={{marginRight: '40px', marginBottom: '24px'}} />
                            </Stack>
                        </Box>
                }
            </Grid2>
        </LeftItem>   
    )
}

export default HistoryCard