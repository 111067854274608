import { Grid, Skeleton } from '@mui/material'
import { Stack } from '@mui/system'
import { useParams } from 'react-router-dom'
import { useGetArtistTracksQuery } from '../../../api/artistApiSlice'
import TrackRow from '../../track/TrackRow'


const TracksGroupedByDate = () => {
    let { id } = useParams()

    const {
        data: tracks,
        isFetching
    } = useGetArtistTracksQuery({id})


    return tracks && tracks !== null && !isFetching
    ? 
        <Grid item xs={12} sx={{overflowY: 'scroll', overflowX: 'hidden', height: '100%', padding: '0 8px', margin: '0 8px'}}>
        {
            ([...new Set(tracks
            .map( track => track.album._id ))].map( (albumID, i) => {
                return (
                        <Stack key={Date.now() + i + albumID} >
                            {/* <Stack direction={'row'} sx={{mb: 2}} >
                                <Link to={`/album/${tracks.find(track => track.album._id === albumID).album._id}`}>
                                    <Avatar 
                                        variant="square" 
                                        alt={tracks.find(track => track.album._id === albumID).album.apple_name} 
                                        src={tracks.find(track => track.album._id === albumID).album.apple_artwork} 
                                        sx={{width: '6vh', height: '6vh'}} />
                                </Link>
                                <Typography sx={{ml: 2}}>
                                    <b>{tracks.find(track => track.album._id === albumID).album.apple_name}</b>
                                    <br/> - 
                                    <i>{'( Sorti le ' + (new Date(tracks.find(track => track.album._id === albumID).apple_release_date)).toLocaleDateString() + ' )'}</i>
                                </Typography>
                            </Stack>   */}
                            
                            { 
                                tracks.filter(track => track.album._id === albumID).map( (track, i) => <TrackRow key={i} track={track} index={i} />)  
                            }
                        </Stack>  
                    )
                })
            )
        }
        </Grid>
    : <Skeleton variant="rectangular" width={'80vw'} height={'80vh'} />
}

export default TracksGroupedByDate