import React from "react";

function AlbumIcon({color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill={color}
        d="M0 10.95A10.965 10.965 0 0111.047 0C17.117.022 22.027 4.98 22 11.06c-.027 6.055-5.014 10.978-11.088 10.94C4.878 21.965-.027 16.995 0 10.95zm4.903-7.125c1.31 1.302 2.603 2.568 3.875 3.855.27.274.469.345.857.174a3.39 3.39 0 014.764 3.386c-.043.542-.255 1.07-.358 1.475 1.43 1.437 2.831 2.849 4.23 4.256 3.149-3.5 2.853-9.285-.707-12.747C13.775.54 8.14.933 4.906 3.825h-.003zM3.837 4.891C.75 8.339.513 14.512 4.957 18.234c4.263 3.57 9.8 2.258 12.08-.108-1.334-1.331-2.662-2.668-4.01-3.985-.1-.098-.371-.125-.512-.066-1.451.61-2.902.383-3.938-.642-1.038-1.027-1.29-2.51-.691-3.93.062-.15.073-.427-.02-.524-1.32-1.366-2.665-2.714-4.029-4.091v.003zm7.186 4.223a1.88 1.88 0 00-1.906 1.855 1.877 1.877 0 001.87 1.89 1.873 1.873 0 00.039-3.747l-.003.002z"
      ></path>
    </svg>
  );
}

export default AlbumIcon;
