import { apiSlice } from "./apiSlice";

export const artistApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getArtist: builder.query({
            query: ({id, user_id}) => ({
                url: `/v1/artists/${id}`,
                method: 'GET',
                params: {user_id}
            })  
        }),
        getArtists: builder.query({
            query: (ids) => ({
                url: `/v1/artists/multiple/${ids}`,
                method: 'GET'
            })  
        }),
        getArtistTracks: builder.query({
            query:  ({id}) => ({
                url: `/v1/artists/${id}/tracks`,
                method: 'GET'
            })
        }),
        getArtistCollabs: builder.query({
            query: ({id}) => ({
                url: `/v1/artists/${id}/collabs`,
                method: 'GET'
            }),
            providesTags: (result, error, arg) => {
                if (result) {
                    return [
                        { type: 'Collab', id: 'LIST' },
                        ...result.map((collab, i) => ({ type: 'Collab', id: collab.artist._id+arg.id }))
                    ]
                } else return [{ type: 'Collab', id: 'LIST' }]
            }  
        })
    })
})

export const {
    useGetArtistQuery,
    useGetArtistTracksQuery,
    useGetArtistCollabsQuery,
    useGetArtistsQuery
} = artistApiSlice