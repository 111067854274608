import { useState } from 'react'
import placeholder from '../../../../assets/placeholder_artist.jpg';
import { Avatar, Box, Button, Chip, Skeleton, Stack, Typography } from '@mui/material'
import { useGetProducersQuery } from '../../../../api/discoverApiSlice'
import { useSelector } from 'react-redux';
import { selectCurrentStyle } from '../../../../actions/discoverSlice';
import { Link } from 'react-router-dom';
import styles from './ProducersFocus.module.css'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import PlayTopTracksButton from '../../../player/utils/PlayTopTracksButton';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import useHorizontalScroll from '../../../../utils/HorizontalScroll';

const ProducersFocus = () => {
    const scrollRef = useHorizontalScroll()
    const selectedStyle = useSelector(selectCurrentStyle)
    var [currentIndex, setCurrentIndex] = useState(0)

    const {
        data: producers,
        isFetching
    } = useGetProducersQuery(selectedStyle) 


    const showNextArtist = () => {
        if (currentIndex + 1 === producers.length || currentIndex > producers.length) {
        document.getElementById('producerList0')?.scrollIntoView({block: "nearest", behavior: "smooth", inline: "center"})
        setCurrentIndex(0)
        } else {
        document.getElementById('producerList'+(currentIndex + 1))?.scrollIntoView({block: "nearest", behavior: "smooth", inline: "center"})
        setCurrentIndex(currentIndex + 1)
        }
    }

    const showPreviousArtist = () => {
        if (currentIndex - 1 === -1 || currentIndex < 0) {
            document.getElementById('producerList'+(producers.length - 1))?.scrollIntoView({block: "nearest", behavior: "smooth", inline: "center"})
            setCurrentIndex(producers.length - 1)
        } else {
            document.getElementById('producerList'+(currentIndex - 1))?.scrollIntoView({block: "nearest", behavior: "smooth", inline: "center"})
            setCurrentIndex(currentIndex - 1)
        }
    }

    const showIndex = (e: any,index: number) => {
        e.target?.scrollIntoView({block: "nearest", behavior: "smooth", inline: "center"})
        setCurrentIndex(index)
    }

    const scrollSelected = () => {
        document.getElementById('producerList'+currentIndex)?.scrollIntoView({block: "nearest", behavior: "smooth", inline: "center"})
    }


    return producers && !isFetching && producers.length > 0
    ? 
        <Box className={styles.producerItem} >
            <Stack className={styles.producerInfos} direction={'column'} onMouseLeave={scrollSelected}>

                <Stack direction={'row'} alignItems={'center'} alignContent={'center'}>

                    <Typography sx={{color: '#fff', padding: '1rem', fontWeight: '700', width: '80%', fontSize: '1.8vmin', '& a' : { textDecoration: 'none'}}} ><a href={`/artist/${producers[currentIndex]._id}`} >{ producers[currentIndex].artist_name }</a></Typography>
                    
                    <Stack direction={'row'} textAlign={'left'} sx={{color: '#fff', pr: 2}} >
                    { 
                        producers[currentIndex].genre_names.length > 0 
                        ? 
                            producers[currentIndex].genre_names.map( (name: string, key: string) => 
                                <Chip color={'secondary'} key={key} label={name} variant="outlined"/>
                            ) 
                        : 
                            <Chip color={'secondary'} key={'key'} label={'--'} variant="outlined"/> 
                    }
                    </Stack>

                    <Stack direction={'row'} alignItems={'center'} alignSelf={'center'} alignContent={'center'} sx={{mr: 2}}>
                        <SettingsInputComponentIcon sx={{fontSize: '2vmin'}}  />
                        <Typography textAlign={'left'} sx={{color: '#fff', p: 1, fontWeight: '700', fontSize: '2vmin'}} >{ producers[currentIndex].refNumber }</Typography>
                    </Stack>
                    
                </Stack>

                <Stack direction={'row'} alignItems={'center'}>

                    <Stack ref={scrollRef} alignItems={'start'} id={'producerList'} className={styles.producers} direction={'row'} sx={{overflow: 'hidden', overflowY: 'scroll', maxWidth: '50%',  pl: 2, pt: 1}}>
                    {
                        producers.map( (artist: Artist, i: number) => 
                            <Button className={styles.selectartistbutton} id={'producerList'+i} key={i} onClick={(e) => showIndex(e, i)} sx={{borderRadius: currentIndex === i ? '2.5vh' : '2vh'}}>
                                <Avatar className={styles.selectartist} 
                                src={artist.avatar_url ? artist.avatar_url.replace("cw.png","cc.png").replace("{w}","100").replace("{h}","80") : artist.apple_avatar ? artist.apple_avatar.replace("cw.png","cc.png").replace("{w}","600").replace("{h}","400") : placeholder} 
                                sx={{width: currentIndex === i ? '5vh!important' : (currentIndex + 1 === i || currentIndex - 1 === i) ? '4vh'  : '3vh', height: currentIndex === i ? '5vh!important' : (currentIndex + 1 === i || currentIndex - 1 === i) ? '4vh'  : '3vh', border: currentIndex === i ? '4px solid #08082f' : 'none'}}
                            />
                            </Button>
                        )
                    }
                    </Stack>
                    
                    <Stack direction={'row'} sx={{position: 'absolute', right: 0, bottom: 0}}>
                        <Button onClick={() => showPreviousArtist()}><Typography textAlign={'left'} sx={{color: '#fff', padding: '1rem'}} ><NavigateBeforeIcon htmlColor='white' sx={{fontSize: '2vmin'}}/></Typography></Button>
                        <Typography textAlign={'left'} sx={{color: '#fff', padding: '1rem'}} ><PlayTopTracksButton style={{  borderRadius: '16px'}} artistId={producers[currentIndex]._id} /></Typography>
                        <Button onClick={() => showNextArtist()}><Typography textAlign={'left'} sx={{color: '#fff', padding: '1rem'}} ><NavigateNextIcon htmlColor='white' sx={{fontSize: '2vmin'}} /></Typography></Button>
                    </Stack>
                </Stack>
            </Stack>

            <Link to={`/artist/${producers[currentIndex]._id}`}>
                <img 
                    alt={producers[currentIndex].artist_name} 
                    height='100%' 
                    width='100%' 
                    src={producers[currentIndex] && producers[currentIndex].avatar_url ? producers[currentIndex].avatar_url.replace("cw.png","cc.png").replace("{w}","800").replace("{h}","600") : placeholder} 
                    className="image-cover" />
            </Link>
        </Box>
    :
        <Skeleton variant="rectangular" width={'100%'} height={'55%'} sx={{bgcolor: '#00000048'}}/>
}

export default ProducersFocus