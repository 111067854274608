import React from 'react'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import styles from '../Discover.module.css'


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#FFFFFF',
    padding: '32px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: 0,
}))

const EventsFocus = () => {
    return (
        <Item sx={{width: '50%', borderRadius: '16px', backgroundColor: '#E5591E', padding: '16px', position: 'relative'}}>
            <p variant="h3" className={styles.date} >Ven. 12 Nov. 2023</p>
            <div className={styles.time_container}>
                <p variant="h3" className={styles.hour} >21:00</p>
                <p variant="h3" className={styles.place} >Paris</p>
                <p variant="h3" className={styles.fuseau} >(UTC +1)</p>
            </div>
        </Item>
    )
}

export default EventsFocus