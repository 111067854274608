import { Stack, Container, Avatar, Button, Skeleton, Chip, Box, Menu, Typography, Grid } from '@mui/material'
import React from 'react'
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import styles from '../../Artist.module.css'
import placeholder from '../../../../assets/placeholder_artist.jpg';
import { Link, useParams } from 'react-router-dom'
import { useGetArtistQuery } from '../../../../api/artistApiSlice';
import { useGetUserQuery, useLikeByTypeMutation } from '../../../../api/userApiSlice';
import AMBadgeListenIn from '../../../../assets/AMBadgeListenIn.svg';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitchIconpng from '../../../../assets/TwitchGlitchWhite.png'
import PlayTopTracksButton from '../../../player/utils/PlayTopTracksButton';
import ArtistProjects from '../ArtistProjects';
import { useState } from 'react'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const parse = require('html-react-parser');

interface ArtistSideMenuProps {
    handleClickOpen: () => void 
}

const chipStyle = { 
    color:'#fff',
    height: '2.6vh',
    width: '4vw',
    borderRadius: '1.3vh',
    fontFamily: 'Montserrat',
    fontSize: '.5vw',
    marginRight: '18px',
    marginBottom: '18px',
    backgroundColor: '#00000080',
    '& span': {padding: '0 18px'}
}


const ArtistSideMenu = ({ handleClickOpen }: ArtistSideMenuProps) => {
    
    let { id } = useParams()
    const [likeArtist] = useLikeByTypeMutation()
    const [mode, setMode] = useState(1)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const openMenu = Boolean(anchorEl)

    const handleClickShare = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    const {
        data: user
    } = useGetUserQuery({})

    const {
        data,
        refetch
    } = useGetArtistQuery({id, user_id: user ? user._id : null})

    const showBio = () => {
		setMode(0)
	}

    const showProjects = () => {
		setMode(1)
	}

    const showMore = () => {
		setMode(2)
	}

    const likeArtistById = async (ID: string) => {
        try {
            const like = await likeArtist({target_id: ID, type: 'artist'}).unwrap()
            refetch()
            if(like) {
                console.log('TODO: Use like')
            }
        } catch (error) {
            handleClickOpen()
        }
    }

    console.log(data)

    return data ? (
    <Grid item lg={4} xs={12} sx={{height: '100%',borderRadius: '16px',}}>
        <Stack 
            spacing={0} 
            sx={{
                height: '100%',
                borderRadius: '16px',
                overflow: 'hidden',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundImage: `url(${data.artist && data.artist.apple_artwork_url ? data.artist.apple_artwork_url.replace("cw.png","cc.png").replace("{w}","1200").replace("{h}","800") : data.artist.avatar_url ? data.artist.avatar_url.replace("cw.png","cc.png").replace("{w}","1200").replace("{h}","800") : placeholder})`
                }}
        >
            <Container sx={{backdropFilter: 'blur(20px)  brightness(70%)', height: '100%', width: '101%', padding: '4.8vh!important', paddingTop: '0!important', position: 'relative'}}>
                <Stack direction={'column'}>

                <Stack direction="row" alignItems="center" sx={{pt: 2, pb: 2}}>
                    <Typography variant='h3' sx={{fontSize: '1.4vmin', whiteSpace: 'nowrap', fontWeight: 800}}>{data.artist.artist_name}</Typography>  
                </Stack>
                <Stack direction={'column'} alignItems={'center'}>
                    <Avatar variant="square" sx={{width: '100%', height: '8vw', borderRadius: '16px'}} className={' image-cover'} alt={data.artist.artist_name} src={data.artist && data.artist.avatar_url ? data.artist.avatar_url.replace("cw.png","cc.png").replace("{w}","1200").replace("{h}","800") : data.artist.apple_avatar ? data.artist.apple_avatar.replace("cw.png","cc.png").replace("{w}","1200").replace("{h}","800") : placeholder } />
                    <Stack direction={'row'} sx={{mt: 1}}>
                        <PlayTopTracksButton style={{p: 2, m: 2, ml: 0, borderRadius: '16px', backgroundColor: '#00000080'}} artistId={data.artist._id} />
                        <Button onClick={() => likeArtistById(data.artist._id)} sx={{p: 2, m: 2, ml: 0, borderRadius: '16px', backgroundColor: '#00000080'}}> <FavoriteIcon htmlColor={data.followed ? '#FF5964' : 'white'} sx={{fontSize: '3vmin'}} /> </Button>
                        <Button onClick={handleClickShare} sx={{width: '6vh', p: 2, m: 2, ml: 0, borderRadius: '16px', backgroundColor: '#00000080'}}> <ShareIcon htmlColor={'white'} fontSize='large' /> </Button>
                            <Menu
                                id="share-menu"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                MenuListProps={{
                                'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleCloseMenu}
                                sx={{p: 0}}
                            >
                                <Button><FacebookIcon sx={{p: 2}}/> </Button>
                                <Button><TwitterIcon sx={{p: 2}}/> </Button>
                                <Button><InstagramIcon sx={{p: 2}}/> </Button>
                                <Button><WhatsAppIcon sx={{p: 2}}/> </Button>
                            </Menu>
                        </Stack>
                </Stack>

                <Stack direction={'row'} alignItems={'center'} alignSelf={'center'} sx={{height: '3vh',  mb: 1, mt: 1}}>
                    <Chip key={'Bio'} label={'Bio'} variant={mode === 0 ? 'outlined' : 'filled' } onClick={showBio} sx={chipStyle}/>
                    <Chip key={'Projets'} label={'Projets'} variant={mode === 1 ? 'outlined' : 'filled' } onClick={showProjects} sx={chipStyle}/>
                    <Chip key={'Dates'} label={'Dates'} variant={mode === 2 ? 'outlined' : 'filled' } onClick={showMore} sx={chipStyle}/>
                </Stack>
                {
                    mode === 0 
                    ? 
                        <Box>
                            <div className={styles.head}>{
                                data.artist.bio && data.artist.bio.length > 0 
                                ? parse(data.artist.bio)
                                : data.artist.apple_notes && data.artist.apple_notes.length > 0 
                                    ? data.artist.apple_notes
                                    : "--"
                            }</div>
                            <div className={styles.content}>{
                                data.artist.bio_details && data.artist.bio_details.length > 0 
                                ? parse(data.artist.bio_details)
                                : "--"
                            }</div>
                        </Box>
                    : mode === 1
                    ?
                        <Box sx={{height: '40vh', overflow: 'hidden'}}>
                            <ArtistProjects /> 
                        </Box>
                    :
                    'soon'    
                }

                <Container sx={{padding: '0!important', position: 'absolute', bottom: '42px', width: '81%'}}>
                    <Stack direction={'row'} sx={{position: 'relative'}}>
                        { data.artist.twitter_name ? <Button><a href={'https://www.twitter.com/' + data.artist.twitter_name} target='blank'><TwitterIcon htmlColor='white'/></a></Button> : ''}
                        { data.artist.instagram_name ? <Button><a href={'https://www.instagram.com/' + data.artist.instagram_name} target='blank'><InstagramIcon htmlColor='white'/></a></Button> : ''}
                        { data.artist.facebook_name ? <Button><a href={'https://www.facebook.com/' + data.artist.facebook_name} target='blank'><FacebookIcon htmlColor='white'/></a></Button> : ''}
                        { data.artist.youtube_name ? <Button><a href={'https://www.youtube.com/@' + data.artist.youtube_name} target='blank'><YouTubeIcon htmlColor='white'/></a></Button> : ''}
                        { data.artist.twitch_name ? <Button><a href={'https://www.twitch.com/' + data.artist.twitch_name} target='blank'><Avatar sx={{width: '20px', height: '25px'}} variant='square' src={TwitchIconpng} /></a></Button> : ''}
                        <Link to={data.artist.apple_url} target='blank' style={{position: 'absolute', right: 0, bottom: 0}}><img alt='apple music link' src={AMBadgeListenIn} /></Link>                            
                    </Stack>
                </Container>
                </Stack>
            </Container>
        </Stack>
    </Grid>
  ) : <Skeleton />
}

export default ArtistSideMenu