import { Avatar, Box, Grid, Skeleton, Typography } from '@mui/material'
import { useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useGetArtistTracksQuery } from '../../../api/artistApiSlice'
import MusicOffIcon from '@mui/icons-material/MusicOff';


const ArtistProjects = () => {
    let { id } = useParams()

    const {
        data: tracks,
        isFetching
    } = useGetArtistTracksQuery({id})

    const tracksToShow = useMemo(() => {
        if (!tracks)
            return {}
        const projects = [...new Set(tracks.map( track => track.album.apple_id+'' ))]

        return projects.map( (albumID, i) => {
            return tracks.filter(track => track.album.apple_id+'' === albumID ).slice(0,1)                   
        }).filter(arr => arr && arr.length > 0)
            
    }, [tracks])


    return tracksToShow && tracksToShow !== null && !isFetching
    ?
        <Box sx={{overflow: 'hidden', height: '100%'}}>
            <Grid item xs={12} sx={{overflowY: 'scroll', overflowX: 'hidden', height: '100%', width: '100%', position: 'relative' }}>
                <Grid container spacing={0} >
                { 
                    tracksToShow.length > 0
                    ? 
                        <Box>
                            <Typography variant='h3' sx={{fontSize: '2vmin', fontWeight: 800 }}>Albums / EPs</Typography>
                            <Grid container>
                            {
                                tracksToShow.filter(track => track[0].main_artists.length === 1 && track[0].main_artists.map(artist => artist._id).includes(id)).length === 0
                                ? <MusicOffIcon/>
                                :
                                    tracksToShow.filter(track => track[0].main_artists.length === 1 && track[0].main_artists.map(artist => artist._id).includes(id))
                                    .map( (track, i) => 
                                        <Grid item xs={6} key={Date.now() + i + track[0]._id} sx={{position: 'relative', p:1}}>
                                            <Link to={`/album/${track[0].album._id}`}>
                                                <Avatar variant="square" alt={track[0].album.apple_name} src={track[0].album.apple_artwork_url ? track[0].album.apple_artwork_url.replace("cw.png","cc.png").replace("{w}","1200").replace("{h}","800") : ''} sx={{width: '100%', height: '100%', objectFit: 'cover'}} />
                                            </Link>
                                        </Grid>
                                    )
                            }
                            </Grid>
                            <Typography variant='h3' sx={{fontSize: '2vmin', fontWeight: 800 }}>Productions</Typography>
                            <Grid container>
                            {
                                tracksToShow.filter(track => track[0].producers.map(artist => artist._id).includes(id)).length === 0
                                ? <MusicOffIcon/>
                                :
                                    tracksToShow.filter(track => track[0].producers.map(artist => artist._id).includes(id))
                                    .map( (track, i) => 
                                        <Grid item xs={4} key={Date.now() + i + track[0]._id} sx={{position: 'relative', p:1}}>
                                            <Link to={`/album/${track[0].album._id}`}>
                                                <Avatar variant="square" alt={track[0].album.apple_name} src={track[0].album.apple_artwork_url ? track[0].album.apple_artwork_url.replace("cw.png","cc.png").replace("{w}","1200").replace("{h}","800") : ''} sx={{width: '100%', height: '100%', objectFit: 'cover'}} />
                                            </Link>
                                        </Grid>
                                    )
                            }
                            </Grid>
                            <Typography variant='h3' sx={{fontSize: '2vmin', fontWeight: 800 }}>Collaborations</Typography>
                            <Grid container>
                            {
                                tracksToShow.filter(track => (track[0].featured_artists.map(artist => artist._id).includes(id)) || (track[0].main_artists.length > 1 && track[0].main_artists.map(artist => artist._id).includes(id))).length === 0
                                ? <MusicOffIcon/>
                                :
                                    tracksToShow.filter(track => track[0].featured_artists.map(artist => artist._id).includes(id) || (track[0].main_artists.length > 1 && track[0].main_artists.map(artist => artist._id).includes(id)))
                                    .map( (track, i) => 
                                        <Grid item xs={2} key={Date.now() + i + track[0]._id} sx={{position: 'relative', p:1}}>
                                            <Link to={`/album/${track[0].album._id}`}>
                                                <Avatar variant="square" alt={track[0].album.apple_name} src={track[0].album.apple_artwork_url ? track[0].album.apple_artwork_url.replace("cw.png","cc.png").replace("{w}","1200").replace("{h}","800") : ''} sx={{width: '100%', height: '100%', objectFit: 'cover'}} />
                                            </Link>
                                        </Grid>
                                    )
                            }
                            </Grid>
                        </Box>
                    :
                        <MusicOffIcon sx={{width: '4vh', height: '4vh', position: 'absolute', right: '50%', top: '50%', transform: 'translate(50%, -50%)'}} />
                }
                </Grid>  
            </Grid>  
        </Box>
    : <Skeleton variant="rectangular" width={'80vw'} height={'80vh'} />
}

export default ArtistProjects