import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    status: 'idle',
    playlists: []
};


export const userSlice = createSlice({
    name: 'artist',
    initialState,
    reducers: {
      addUserPlaylist: (state, action) => {
        state.playlists = [...state.playlists, ...action.payload]
      }
    }
});

export const { addUserPlaylist } = userSlice.actions;

export const selectUserPlaylists = (state) => state.player.playlists;

export default userSlice.reducer;
