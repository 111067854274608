import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { selectPlaylistTrack, setAddPlaylistTrack } from '../../../actions/artistSlice';
import { useAddTarckUserPlaylistMutation, useCreateUserPlaylistMutation, useGetUserPlaylistsQuery } from '../../../api/userApiSlice';
import { Stack } from '@mui/system';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'


const AddPlaylistDialog = () => {

    const dispatch = useDispatch()
    const track = useSelector(selectPlaylistTrack)
    const [createPlaylist] = useCreateUserPlaylistMutation()
    const [addTrackToUserPlaylist] = useAddTarckUserPlaylistMutation()
    const [name, setName] = useState(false)
    const [playlistName, setPlaylistName] = useState('')
    const {
        data: playlists,
        refetch
    } = useGetUserPlaylistsQuery(
    )

    useEffect(() => {

    })

    // const showConfirmDialog = (track) => {
    //     setOpen(true)
    //     console.log(track)
    // }

    const cancelDialog = () => {
        setPlaylistName('')
        setName(false)
        dispatch(setAddPlaylistTrack({track: null}))
    }

    const setNewPlaylistName = async () => {
        if (name) {
            if (playlistName.length > 0) {
                await createPlaylist({name: playlistName})
                refetch()
                setPlaylistName('')
                setName(false)
            } else {
                setPlaylistName('')
                setName(false)
            }
        } else {
            setName(true)
        }
    }

    const updatePlaylistName = (name) => {
        setPlaylistName(name)
    }

    const addTrackToPlaylist = async (playlistId) => {
        await addTrackToUserPlaylist({playlistId: playlistId, trackId: track._id})
        refetch()
        setPlaylistName('')
        setName(false)
        dispatch(setAddPlaylistTrack({track: null}))
    }

    return track 
        ? 
            <Dialog
                open={track !== null}
                onClose={cancelDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">
                    {'Ajouter à la playlist'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {
                            'Oú voulez vous ajouter ' + track.apple_title +' de '
                            + track.main_artists.map( (artist, i) =>  {
                                return i === track.main_artists.length - 2
                                    ?  artist.artist_name + ' et ' 
                                    :  i === track.main_artists.length - 1
                                        ? artist.artist_name
                                        : artist.artist_name + ', '
                                }).join('') + ' ?'
                        }
                    </DialogContentText>
                    <DialogContent>
                        {
                            playlists 
                            ?
                                <Stack>
                                    {
                                        name 
                                        ?
                                            <TextField
                                                required
                                                id="outlined-required"
                                                label="Nom de la playlist"
                                                placeholder="Ma playlist"
                                                onChange={(e) => updatePlaylistName(e.target.value)}
                                            />
                                        :
                                            playlists.map( playlist => <Button key={playlist._id} onClick={() => addTrackToPlaylist(playlist._id)}>{playlist.name}</Button>)

                                    }
                                    <Button startIcon={<PlaylistAddIcon />} sx={{mt:2}} onClick={() => setNewPlaylistName()}>{ name && playlistName.length > 0 ? 'Valider la création' : 'Créer une playlist' }</Button>
                                </Stack>
                            :
                                <Stack>
                                    {
                                        name 
                                        ?
                                            <TextField
                                                required
                                                id="outlined-required"
                                                label="Nom de la playlist"
                                                placeholder="Ma playlist"
                                                onChange={(e) => updatePlaylistName(e.target.value)}
                                            />
                                        :
                                            <Typography>Vous n'avez pas de playlist</Typography>

                                    }
                                    <Button startIcon={<PlaylistAddIcon />} sx={{mt:2}} onClick={() => setNewPlaylistName()}>{ name && playlistName.length > 0 ? 'Valider la création' : 'Créer une playlist' }</Button>
                                </Stack>

                        }
                    </DialogContent>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelDialog} autoFocus >Annuler</Button>
                </DialogActions>
            </Dialog>
        :
            ''  
}




export default AddPlaylistDialog