import { Avatar, Box, Button, Chip, Stack, Typography, Skeleton } from '@mui/material';
import useHorizontalScroll from '../../../../utils/HorizontalScroll';
import styles from './BestTrackFocus.module.css'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import PlayArrow from '@mui/icons-material/PlayArrow';
import { Link } from 'react-router-dom';
import { playTrack } from '../../../../services/MusicPlayerApi';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import placeholder from '../../../../assets/placeholder_artist.jpg';
import { useState } from 'react';

interface BestTrackFocusHoverProps {
    tracks: [any]
}

const BestTrackFocusHover = ({tracks}:BestTrackFocusHoverProps) => {
    const scrollRef = useHorizontalScroll()
    var [currentIndex, setCurrentIndex] = useState(0) 



    const showNextArtist = () => {
        if (currentIndex + 1 === tracks.length || currentIndex > tracks.length) {
        document.getElementById('trackList0')?.scrollIntoView({block: "nearest", behavior: "smooth", inline: "center"})
        setCurrentIndex(0)
        } else {
        document.getElementById('trackList'+(currentIndex + 1))?.scrollIntoView({block: "nearest", behavior: "smooth", inline: "center"})
        setCurrentIndex(currentIndex + 1)
        }
    }

    const showPreviousArtist = () => {
        if (currentIndex - 1 === -1 || currentIndex < 0) {
            document.getElementById('trackList'+(tracks.length - 1))?.scrollIntoView({block: "nearest", behavior: "smooth", inline: "center"})
            setCurrentIndex(tracks.length - 1)
        } else {
            document.getElementById('trackList'+(currentIndex - 1))?.scrollIntoView({block: "nearest", behavior: "smooth", inline: "center"})
            setCurrentIndex(currentIndex - 1)
        }
    }

    const showIndex = (e: any,index: number) => {
        e.target?.scrollIntoView({block: "nearest", behavior: "smooth", inline: "center"})
        setCurrentIndex(index)
    }

    if(tracks.length < currentIndex) {
        setCurrentIndex(0)
    }
    
    const scrollSelected = () => {
        document.getElementById('trackList'+currentIndex)?.scrollIntoView({block: "nearest", behavior: "smooth", inline: "center"})
    }

    return tracks.length > currentIndex 
    ? (
        <Box className={styles.trackItem} onMouseLeave={scrollSelected}>
            <Stack className={styles.trackInfos}>

                <Stack direction={'row'}>
                    <Stack sx={{width: '80%'}}>
                        <Stack direction={'row'} alignItems={'center'} sx={{p: 2, pb: 0}}>
                            <Typography textAlign={'left'} sx={{color: '#fff', mr: 2}}><span><b>{ tracks[currentIndex].track.apple_title }</b></span></Typography>
                            <Typography textAlign={'left'} sx={{color: '#fff'}}><span><i>{  `(${tracks.length > 0 && tracks[currentIndex].track.album ? tracks[currentIndex].track.album.apple_name : '--'})`  }</i></span></Typography>
                        </Stack>

                        <Typography textAlign={'left'} sx={{color: '#fff', pl:2, pt: 0, m: 0}}><span><i>{' par ' + (tracks.length > 0 && tracks[currentIndex].track.album && tracks[currentIndex].track.album.apple_artist_name ? tracks[currentIndex].track.album.apple_artist_name : "--" )+ '' }</i></span></Typography>
                    </Stack>
                    <Stack alignSelf={'center'} sx={{width: '20%'}}>
                        <Stack direction={'row'} textAlign={'left'} alignSelf={'end'} sx={{color: '#fff', p: 0, }} >
                        { 
                            tracks[currentIndex].track.apple_genre_names.length > 0 
                                ? tracks[currentIndex].track.apple_genre_names.filter((name: string) => name !== 'Musique').map( (name: string, key: number) => 
                                    <Chip sx={{mr: 1}} color={'secondary'} key={key} label={name === 'Musique' ? 'Général': name} variant="outlined"/>) 
                                : '--' 
                        }
                        </Stack>
                    </Stack>
                </Stack>

                <Stack direction={'row'}>
                    <Stack direction={'row'} alignItems={'center'} sx={{p: 2}}>
                        <FormatIndentIncreaseIcon/>
                        <Typography textAlign={'left'} sx={{color: '#fff', padding: '1rem', fontWeight: 700}}>{ tracks[currentIndex].position }</Typography>
                        <TrendingUpIcon/> 
                        <Typography textAlign={'left'} sx={{color: '#fff', padding: '1rem', fontWeight: 700}}>{ '+'+tracks[currentIndex].position_gain}</Typography>
                    </Stack>
                        
                    <Stack ref={scrollRef} id={'trackList'} alignItems={'center'} alignContent={'center'} alignSelf={'center'} direction={'row'} sx={{overflow: 'hidden', overflowY: 'scroll', maxWidth: '50%'}}>
                    {
                        tracks.map( (trackStat, i) => 
                            <Button key={i} id={'trackList' + i} className={styles.selectartistbutton} onClick={(e) => showIndex(e, i)}>
                                <Avatar 
                                    className={styles.selectartist} 
                                    variant='square' 
                                    src={trackStat.track.album ? trackStat.track.album.apple_artwork_url.replace("{w}","1200").replace("{h}","800") : placeholder} 
                                    sx={{width: currentIndex === i ? '5vh!important' : (currentIndex + 1 === i || currentIndex - 1 === i) ? '4vh' : '3vh', height: currentIndex === i ? '5vh!important' : (currentIndex + 1 === i || currentIndex - 1 === i) ? '4vh' : '3vh', border: currentIndex === i ? '4px solid #08082F' : 'none', borderRadius: '8px'}}
                                />
                            </Button>)
                    }
                    </Stack>

                    <Stack direction={'row'} sx={{position: 'absolute', right: 0, bottom: 0}}>
                        <Button onClick={() => showPreviousArtist()}><Typography textAlign={'left'} sx={{color: '#fff', padding: '1rem'}} ><NavigateBeforeIcon /></Typography></Button>
                        <Button onClick={() => playTrack([new MusicKit.MediaItem({type: 'song', id: tracks[currentIndex].track.apple_id+'', attributes: {}})], 0)}><PlayArrow htmlColor='white' /></Button>
                        <Button onClick={() => showNextArtist()}><Typography textAlign={'left'} sx={{color: '#fff', padding: '1rem'}} ><NavigateNextIcon /></Typography></Button>
                    </Stack>
                </Stack>
            </Stack>
            <Link to={`/track/${tracks[currentIndex].track._id}`}>
                <img 
                    alt="The Weeknd" 
                    height='100%' 
                    width='100%'
                    src={tracks[currentIndex].track.album && tracks[currentIndex].track.album.apple_artwork_url ? tracks[currentIndex].track.album.apple_artwork_url.replace("{w}","500").replace("{h}","500") : placeholder} 
                    className="image-cover" />
            </Link>
        </Box>
    )
    : <Skeleton />
}

export default BestTrackFocusHover